$fontPath: '../../webfonts/';

@font-face {
	font-family: 'apercu';
	src: url('#{$fontPath}font-apercu/apercu-regular-pro.woff2') format('woff2'),
	url('#{$fontPath}font-apercu/apercu-regular-pro.woff') format('woff'),
	url('#{$fontPath}font-apercu/apercu-regular-pro.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'apercu';
	src: url('#{$fontPath}font-apercu/apercu-medium-pro.woff2') format('woff2'),
	url('#{$fontPath}font-apercu/apercu-medium-pro.woff') format('woff'),
	url('#{$fontPath}font-apercu/apercu-medium-pro.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'apercu';
	src: url('#{$fontPath}font-apercu/apercu-bold-pro.woff2') format('woff2'),
	url('#{$fontPath}font-apercu/apercu-bold-pro.woff') format('woff'),
	url('#{$fontPath}font-apercu/apercu-bold-pro.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}