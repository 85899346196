@use "../mixins/mq";
@use "../_base/typography";
@use "../atoms/action";

.nav--meta-nav {
	background-color: var(--color-neutral-grey-500);
	color: var(--color-neutral-white);

	@extend .font-size-h5;

	height: 6rem;


	.header__nav--opened & {
		@include mq.mq($until: large) {
			display: block;
			padding: 5rem 1.6rem 2rem 1.6rem;
			color: var(--color-neutral-black);
		}

	}

	@include mq.mq($from: large) {
		margin-top: 0;
		padding-bottom: 0;
		height: 6rem;


	}

	.nav__wrap {
		align-content: center;
		align-items: center;

		@include mq.mq($from: large) {
			padding-right: 2.5rem;
		}
	}
	.nav__item--parent {
		width: 100%;

		padding: 1.25rem 0;

		@include mq.mq($from: large) {
			width: auto;
			margin-left: 2.5rem;
			margin-right: 0;
			padding: 0;
		}
	}
	.nav__item {

		@include mq.mq($from: large) {
			margin-top: 0.2rem;
		}
		&--contact {
			display: flex;
			justify-content: flex-end;
			.nav__link {
				@extend .action-contact;
			}

		}
		&.nav__item--contact .nav__link {
			&.is-active {
				color: var(--color-neutral-white);
			}
		}
	}

	.nav__link {


		font-size: 1.8rem;
		line-height: 150%;
		letter-spacing: 0.02rem;
		font-style: normal;
		font-weight: var(--font-weight-400);
		color: var(--color-neutral-white);
		@include mq.mq($from: large) {
			font-size: 2rem;
			line-height: 160%;
		}

		.header__nav--opened & {
			@include mq.mq($until: large) {
				color: var(--color-neutral-white);
			}
		}
		&.is-active {
			color: var(--color-primary-900);
		}




		.not-touch & {
			&:hover {
				color: var(--color-primary-700);
			}
		}
	}
}
