/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  text-align: inherit;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, iframe {
  display: block;
}

iframe {
  border: 0;
}

body {
  line-height: 1;
}



blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  appearance: none;
  border: 0;
  background: none;
  cursor: pointer;
  font-family: inherit;
  line-height: inherit;
}

* {
  box-sizing: border-box;
}

address {
  font-style: normal;
}

select {
  font-family: inherit;
}
/*
select, textarea, input, a, button {
  outline-color: var(--outline-color,currentColor);
  outline-offset: 0.3rem;

}
*/

.link,
a {
  color: var(--link-color, inherit);
  text-decoration: var(--link-text-decoration, none);
  transition: color var(--animation-duration) var(--animation-ease);
  will-change: color;

  &:focus,
  &:hover {
    --link-text-decoration: var(--link-text-decoration-active, underline);
    --link-color: var(--link-color-active);
  }
}

:focus-visible {
  outline-style: solid;
  outline-color: var(--outline-color,currentColor);
  outline-offset: 0.3rem;
  outline-width: .1rem;
}

img {
  user-select: none;
}

legend {
  display: block;
}

input,
select,
textarea,
button {
  padding: 0;
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  border: none;
  border-radius: 0;
  background: none;
}

legend {
  display: block;
}

::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
::-webkit-search-results-button {
  -webkit-appearance: none;
}

*, *:before, *:after {
  box-sizing: inherit;
}