@use "../mixins/mq";
@use "../utils/visually-hidden";


@include mq.mq($until: large) {
  .header__nav--opened {
    overflow: hidden;
    --header-nav-overlay-display: block;
  }
  .visible-mobile-menu {
    --header-content-visibility: visible;
  }
}

.header {
  position: sticky;
  z-index: 50;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: var(--header-height);
  background-color: var(--color-neutral-white);


  transition: transform var(--animation-duration) var(--animation-ease);

  padding: 1.5rem 1.6rem;


  &--scroll {
    @include mq.mq($from: large) {
      transform: translateY(-6rem);
    }
  }

  &__home-link {

    display: none;


    @include mq.mq($from: large) {
      display: inline-flex;
      margin-bottom: 0.9rem;
    }

    > * {
      width: 130px;

      @include mq.mq($from: large) {
        height: auto;
        width: var(--header-image-width);
        flex-shrink: 0;
      }
    }
  }

  &__home-link-text {
    @extend .visually-hidden;
  }

  &__nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    transform: translate3d(-100%, 0, 0);
    background-color: var(--color-neutral-white);
    overflow-x: hidden;
    overflow-y: var(--scroll-behavior);
    -webkit-overflow-scrolling: touch;
    transition: transform var(--animation-duration) var(--animation-ease);
    flex-direction: column-reverse;
    justify-content: flex-end;


    @include mq.mq($from: medium, $until: large) {
      width: 37.5rem;
    }

    @include mq.mq($from: large) {
      position: relative;
      position: unset;
      height: auto;
      display: flex;
      justify-content: flex-end;

      transform: none;
      background: none;
      overflow: visible;
    }

    .header__nav--opened & {

      @include mq.mq($until: large) {
        transform: translate3d(0, 0, 0);
        background-color: var(--color-neutral-grey-500);
      }
      @include mq.mq($from: large) {
        transform: none;
      }
    }
  }

  &__nav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--color-neutral-black-transparent);
    display: none;
    z-index: 0;
    display: var(--header-nav-overlay-display, none);

    .header__nav--opened & {
      @include mq.mq($from: medium, $until: large) {
        display: block;
      }
    }
  }

  &__nav-main {
    display: flex;

    padding: 10.2rem 1.6rem 0 1.6rem;
    flex-wrap: nowrap;
    align-items: flex-end;
    justify-content: flex-start;
    flex-direction: column;

    .header__nav--opened & {

      @include mq.mq($until: large) {
        background-color: var(--color-neutral-grey-500);
        color: var(--color-neutral-white);
      }
    }

    @include mq.mq($from: large) {
      flex-direction: row;
      padding: 3.4rem 0 3rem 2.5rem;
    }
  }

  &__nav-meta {


    @include mq.mq($from: large) {
      background-color: var(--color-neutral-grey-500);
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .lang-chooser {
      width: auto;
    }

    .nav--meta-nav {
      width: auto;
    }
  }

  &__hamburger {
    position: relative;

    z-index: 2;

    @include mq.mq($from: large) {
      display: none;
      visibility: hidden;
    }
  }

  &__mobile-home {
    &-link {
      svg {
        max-height: 4rem;
      }

    }
  }

  @include mq.mq($from: large) {
    padding: 0;
  }
}

.header__mobile-home-link {
  @include mq.mq($from: large) {
    display: none;
  }
}
