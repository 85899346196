@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/mq";
@use "../mixins/background-svg";
@use "../_base/typography";

.link-download {
    display: flex;
    width: 100%;
    padding-left: 1.5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-right: 1.5rem;
    border-bottom: 1px solid var(--color-neutral-grey-200);
    text-decoration: none;
    font-size: var(--font-size-350);
    font-weight: var(--font-weight-500);
    line-height: 1.5;
    align-items: flex-start;

    &--border-top {
        border-top: 1px solid var(--color-neutral-grey-200);
    }

    &::before {
        content: '';
        width: 2.5rem;
        height: 2.5rem;
        min-width: 2.5rem;
        margin-right: 1.5rem;
        @include background-svg.params(svg.$svg-document, transparent, center, center, no-repeat, 100%, 100%, colors.$color-black);

        @include mq.mq($from: large) {
            width: 3rem;
            height: 3rem;
            min-width: 3rem;
        }
    }
    
    &::after {
        content: '';
        width: 2.5rem;
        height: 2.5rem;
        min-width: 2.5rem;
        margin-left: auto;
        @include background-svg.params(svg.$svg-download, transparent, center, center, no-repeat, 100%, 100%, colors.$color-primary-900);

        @include mq.mq($from: large) {
            width: 3rem;
            height: 3rem;
            min-width: 3rem;
        }
    }

    &:hover, &:active {
        background-color: var(--color-primary-600);
    }
}

.accordion__download {
    margin-bottom: 5rem;

    &.accordion--active {
        margin-bottom: 4rem;
    }

    &:last-child {
        margin-bottom: 0;
    }
}