@use "../mixins/mq";
@use "../mixins/background-svg";
@use "../_config/colors";
@use "../_base/svg";

/* for formie */
[data-conditionally-hidden],
[data-fui-page-hidden],
[data-conditionally-hidden] {
    display: none;
}

.error,
.fui-error {
    --label-color: var(--color-error);
    --input-select-arrow-bg: var(--input-select-arrow-error);
    --input-border-color: var(--color-error);

}

//group for label and input
.field {
    flex: 1;
    margin-bottom: var(--field-margin-bottom);

    &--hide {
        display: none;
    }

    &__errors {
        font-size: var(--field-error-font-size);
        line-height: var(--field-error-line-height);
        color: var(--field-error-color, currentColor);
        margin: var(--field-error-margin);
    }
    &__option-group {
        display: grid;
        gap: 1.5rem;
    }

    &__group {
        position: relative;
    }

	&__instructions {
		font-size: var(--field-instructions-font-size, 1.2rem);
	}

    &__icon {
        position: absolute;
        top: 0;
        left: 1.5rem;
        align-items: center;
        justify-content: center;
        bottom: 0;
        display: grid;
        width: 1.6rem;

        ~ .input {
            padding-left: 4.5rem;
        }
    }
    &--salutation {
        select {
            max-width: calc(50% - 2.5rem);
            @include mq.mq($from: large) {
                max-width: calc(100% / 6);
            }
        }
    }
}


.input {
    // Resets
    font-family: inherit;
    font-size: var(--input-font-size);
    line-height: var(--input-line-height);
    font-weight: var(--input-font-weight);
    margin: 0;
    appearance: none;
    color: var(--input-color, currentColor);
    width: 100%;
    display: block;
    padding: 0 0 0.5rem 0;

    border: 1px solid var(--input-border-color);
    border-width: var(--input-border-width,1rem);
    border-radius: var(--input-border-radius);
    background-color: var(--input-background-color);
    background-clip: padding-box;
    transition: var(--input-transition);
    min-height:3.2rem;



    &::placeholder {
        color: var(--color-neutral-grey-300);
        opacity: 1;
        font-size: 1.8rem;
        font-style: normal;
        font-weight: var(--font-weight-500);
        line-height: 150%;
    }

    &::-ms-input-placeholder { /* Edge 12 -18 */
        color: var(--color-neutral-grey-300);
        font-size: 1.8rem;
        font-style: normal;
        font-weight: var(--font-weight-500);
        line-height: 150%;
    }

    &--outline {
        border-width: var(--input-outline-border-width);
        --input-padding-inline: var(--input-outline-padding-inline);
        --input-padding-block: var(--input-outline-padding-block);
        --input-select-arrow-bg-position: right 1rem center;
        --input-select-padding-right: 3.5rem;
    }

    &--file {
        &::file-selector-button {
            color: var(--color-neutral-white);
            padding: 0 1rem;
            margin: -1.2rem 1.5rem -1.2rem 0;
            isolation: isolate;
            border: 0;
            appearance: none;
            height: 4.4rem;
            text-align: center;
            font-size: var(--input-font-size);
            background-color: var(--color-primary-900);
        }
    }

    &--dropdown,
    &--select {
        //icon

        &:not([multiple]):not([size]) {
            padding-right: var(--input-select-padding-right);
            background: background-svg.svg-url(svg.$svg-arrow-down-s) no-repeat right center;
            background-size: 1.5rem;

        }

        &[size],
        &[multiple] {
            height: auto;
        }

        option:disabled {
            display: none;
        }

        &.no-value {
            color: var(--color-neutral-grey-300);
            font-size: 1.8rem;
            font-style: normal;
            font-weight: var(--font-weight-500);
            line-height: 150%;
        }

    }
    /* don't hide it, needed for screen reader */
    &--radio,
    &--checkbox {
        position: absolute;
        width: var(--check-size);
        height: var(--check-size);
		border: var(--check-color) var(--check-border-width) solid;
        padding: 0;
        background: none;
        border: none;
    }
    &--radio {
        border-radius: 50%;
    }

    &--textarea {
        min-height: 7.7rem;
        resize: vertical;

    }
}


.label {
    font-size: var(--label-font-size);
    line-height: var(--label-line-height);
    color: var(--label-color, currentColor);

    &--legend {
        margin-bottom: 1rem;
    }

    &--option {
        display: block;
        padding: 0;
        padding-left: var(--label-option-padding-left);
        font-size: var(--label-option-font-size, inherit);
        position: relative;
        min-height: var(--check-size);
        line-height: var(--check-size);
        user-select: none;
        cursor: pointer;

        &::before {
            content: '';
            cursor: pointer;
            display: block;
            position: absolute;
            left: 0;
            width: var(--check-size);
            height: var(--check-size);
            border: var(--check-color) var(--check-border-width) solid;
            background: var(--check-bg-color,transparent);
            background-image: var(--check-bg-image,none);
			
            transition: var(--check-transition);
            border-radius: .2rem;
            background-size: 100% 100%;
            background-repeat: no-repeat;

            [type=radio] + & {
                border-radius: 50%;
                background-image: none;
				background-image: var(--check-radio-bg-image,none);
            }

            :checked + & {
                --check-bg-color: var(--check-bg-color-checked);
        
            }
        }
    }


}
