@use "../mixins/mq";

.section {
	position: relative;
	overflow: hidden;
}

.section--spacing {
	padding-top: 4rem;
	padding-bottom: 4rem;

	@include mq.mq($from: large) {
		padding-top: 12rem;
		padding-bottom: 12rem;
	}
}


.section--spacing-top {
	padding-top: 4rem;


	@include mq.mq($from: large) {
		padding-top: 12rem;
	}
}
.imageText .section--spacing-top {
	padding-top: 5rem;
	@include mq.mq($from: large) {
		padding-top: 10rem;
	}
}
.c-first {
	.section--spacing-top {
		padding-top: 2.5rem;

		@include mq.mq($from: large) {
			padding-top: 2.5rem;
		}
	}
}


.section__title {
	margin-bottom: 4rem;

	&--contact {
		margin-bottom: 2.5rem;

		@include mq.mq($from: large) {
			margin-bottom: 5rem;
		}
	}
}
