@use "../mixins/mq";

.nav--footer-nav {
	margin-top: 1rem;
	@include mq.mq($from: large) {
		order: 2;
		flex: none;
		margin-top: 0;
		margin-left: 2.5rem;
		flex: 1;
	}
	.nav__wrap {
		flex-direction: row;

		@include mq.mq($from: large) {
			flex-direction: row;
		}
	}

	.nav__item {
		margin-top: 0;


		@include mq.mq($from: large) {
			margin-top: 0;
			margin-left: 0;
		}

		&:not(:last-of-type){
			&:after {
				content: '|';
				margin-right: 0.4rem;
			}
		}

	}

	.nav__link {
		color: var(--white);

		.not-touch & {
			&:hover {
				color: var(--color-primary-700);
			}
		}

		&.is-active {
			color: var(--color-primary-900);
		}
	}
}
