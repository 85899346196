@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/mq";
@use "../mixins/background-svg";
@use "../_base/typography";

.accordion {



  &__header {

    font-size: 2.2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    padding-bottom: 0.5rem;
    border-bottom: 0.2rem solid var(--color-neutral-black);

    position: relative;
    cursor: pointer;

    &:after {
      content: '';
      --icon-width: 2rem;
      --icon-height: 2rem;
      width: 2rem;
      height: 2rem;
      @include background-svg.params(svg.$svg-arrow-down-s, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
      background-size: 2rem;
      position: absolute;
      right: 0;
      bottom: 1rem;
      transition: var(--animation-duration) var(--animation-ease);

    }

  }
  &__content {
    display: none;
    padding: 1rem 0;
  }
  &--active {
    .accordion__header {
      &:after {
        transform: rotate(180deg);

      }
    }
    .accordion__content {
      display: block
    }
  }
}