@use "../mixins/mq";

.nav--social-nav {
	width: auto;
	margin-bottom: 5rem;
	display: grid;
	grid-column-gap: 5rem;
	@include mq.mq($from: large) {
		order: 3;
		flex: none;
		margin-bottom: 0;

	}
	.nav__wrap {
		height: fit-content;
		gap: 5rem;
		@include mq.mq($from: large) {
			gap: 2.5rem;
		}
	}

	.nav__item {
		margin: 0;
		height: fit-content;
		&:not(:first-child) {


			@include mq.mq($from: large) {

			}
		}

		&:last-of-type {
			.icon {
				margin-right: 0;
			}
		}
	}




	.nav__link {
		color: var(--color-neutral-white);
		display: inline-block;

	}


	.icon {
		--icon-width: 2.5rem;
		--icon-height: 2.5rem;

		opacity: 0.3;

	}
	.icon svg {
		width: 2.5rem;
		height: 2.5rem;

	}

	.not-touch & {
		.nav__link:hover {
			color: var(--color-primary-800);
			svg {
				opacity: 1;
			}

		}
	}
}
