@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/mq";
@use "../mixins/background-svg";

.heading {

  &__title {
    //padding: 0 1.6rem;
    @include mq.mq($from: medium) {
      padding: 0;
    }
    .h5 {
      font-weight: var(--font-weight-400);
    }
    p {
      margin-top: 1rem;
      @include mq.mq($from: large) {
        margin-top: 1.5rem;
      }

      &:first-child {
        margin-top: 0;
      }
    }
    h1, h2, h3, h4, h5, h6 {
      margin-top: 1rem;
      @include mq.mq($from: large) {
        margin-top: 1.5rem;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }
  &__image {

    padding: 0;
    @include mq.mq($from: medium) {
      padding: 0 2.5rem;
    }
    @include mq.mq($from: large) {
      padding: 0 5rem;
    }

    figure {
      height: 100%;
      -webkit-mask-image: background-svg.svg-url(svg.$svg-heading-mask-mobile);
      mask-image: background-svg.svg-url(svg.$svg-heading-mask-mobile);
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: contain;
      mask-size: contain;
      -webkit-mask-position: center;
      mask-position: center;

      @include mq.mq($from: medium) {
        -webkit-mask-image: background-svg.svg-url(svg.$svg-heading-mask-tablet);
        mask-image: background-svg.svg-url(svg.$svg-heading-mask-tablet);
      }
      @include mq.mq($from: large) {
        -webkit-mask-image: background-svg.svg-url(svg.$svg-heading-mask-desktop);
        mask-image: background-svg.svg-url(svg.$svg-heading-mask-desktop);
      }

    }

  }
  &__cta {
    position: relative;
    padding: 0 1.6rem;
    @include mq.mq($from: medium) {
      padding: 0;
    }
    .action {
      position: relative;
      @include mq.mq($from: large) {
        position: absolute;
      }
    }
  }
}