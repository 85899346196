@use "../mixins/mq";
@use "../mixins/col";
@use "../_base/typography";
@use "../atoms/action";
@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/background-svg";



.popup {
  position: fixed;
  right: 0;
  width: calc(100% - var(--wrapper-gap));
  height: auto;
  background: var(--color-primary-900);
  color: var(--color-neutral-white);
  padding: 2rem 5.8rem 2rem 2rem;
  z-index: 100;
  border-radius: 2rem 0 0 2rem;
  top: 50%;
  transform: translateY(-50%);

  @include mq.mq($from: large) {
    width: 44.4rem;
    padding: 5rem 5.1rem 5rem 5rem;
    border-radius: 4rem 0 0 4rem;
  }
  &--inactive {
    display: none;
  }

  &__close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    --icon-width: 2rem;
    --icon-height: 2rem;
    --icon-color: var(--color-neutral-white);

    display: var(--icon-display, block);
    font-style: normal;
    color: var(--icon-color, currentColor);
    width: var(--icon-width, auto);
    height: var(--icon-height, auto);
    cursor: pointer;
    @include mq.mq($from: large) {
      --icon-width: 2.5rem;
      --icon-height: 2.5rem;
    }

    &:after {
      content: '';
      display: block;
      width: var(--icon-width, auto);
      height: var(--icon-height, auto);
      @include background-svg.params(svg.$svg-close, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-white);
    }
  }
  &__content {

    > div {
      display: none;
    }
    p {
      margin-top: 0.5rem;

      &:not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6) {
        font-size: var(--font-size-350);
        font-style: normal;
        font-weight: var(--font-weight-400);
        line-height: 150%;
      }

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  &--nolax {
    .popup__content{
      &-nolax{
        display: block;
      }
    }
  }
  &--emerell {
    .popup__content{
      &-emerell{
        display: block;
      }
    }
  }
  &--meraxo {
    .popup__content{
      &-meraxo{
        display: block;
      }
    }
  }
}