@use "../mixins/mq";
@use "../_base/svg";
@use "../_config/colors";
@use "../_base/typography";
@use "../mixins/background-svg";

.action {
  position: relative;
  display: inline-flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  overflow: visible;
  text-decoration: none;
  transition: background-color var(--animation-duration) var(--animation-ease), color var(--animation-duration) var(--animation-ease), border-color var(--animation-duration) var(--animation-ease);
  cursor: pointer;
  font-variant-ligatures: none;

  &-email,
  &-phone {
    --button-primary-min-width: unset;
  }
}

.action-primary {
  min-height: var(--button-primary-min-height);
  //min-width: var(--button-primary-min-width);
  padding: 0 2rem;

  font-size: var(--button-font-size);
  @extend .font-weight-400;
  color: var(--color-neutral-white);

  border-radius: calc(#{var(--button-primary-min-height)} / 2);
  background-color: var(--color-primary-900);


  &:after {
    width: 2rem;
    height: 2rem;
    content: '';

    @include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, center, center, no-repeat, 1.4rem, 1.4rem, colors.$color-neutral-white);
  }


  &.disabled {
    background-color: var(--color-neutral-grey-300);


  }

  .not-touch & {
    &.active,
    &:hover {

      background-color: var(--color-primary-800);

    }

    &:active {
      color: var(--color-neutral-white);
      background-color: var(--color-primary-900);

      &:after {
        @include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, center, center, no-repeat, 1.4rem, 1.4rem, colors.$color-neutral-white);
      }
    }
  }

  &:focus {
    outline: 0.1rem solid var(--color-neutral-grey-300);
    outline-offset: 0.5rem;
  }

}

.action-primary--inverted {
  @extend .action-primary;
  color: var(--color-primary-900);


  background-color: var(--color-neutral-white);

  &:after {
    @include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, center, center, no-repeat, 1.4rem, 1.4rem, colors.$color-primary-900);
  }


  &.disabled {
    color: var(--color-neutral-white);
    background-color: var(--color-neutral-grey-300);

    &:after {
      @include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, center, center, no-repeat, 1.84rem, 1.84rem, colors.$color-neutral-white);
    }
  }

  .not-touch & {
    &.active,
    &:hover {
      color: var(--color-primary-900);
      background-color: var(--color-primary-700);


    }

    &:active {
      color: var(--color-primary-900);
      background-color: var(--color-neutral-white);

      &:after {
        @include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, center, center, no-repeat, 1.4rem, 1.4rem, colors.$color-primary-900);
      }
    }
  }


}


.action-secondary {
  min-height: var(--button-primary-min-height);
  //min-width: var(--button-primary-min-width);
  padding: 0 2rem;

  font-size: var(--button-font-size);
  @extend .font-weight-400;
  color: var(--color-primary-900);

  border-radius: calc(#{var(--button-primary-min-height)} / 2);
  border: 1px solid var(--color-primary-900);


  &:after {
    width: 2rem;
    height: 2rem;
    content: '';

    @include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, center, center, no-repeat, 1.4rem, 1.4rem, colors.$color-primary-900);
  }


  &.disabled {
    color: var(--color-neutral-grey-300);
    border-color: var(--color-neutral-grey-300);

    &:after {
      @include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, center, center, no-repeat, 1.4rem, 1.4rem, colors.$color-neutral-grey-300);
    }


  }

  &:focus {
    outline: 0.1rem solid var(--color-neutral-grey-300);
    outline-offset: 0.5rem;

  }

  .not-touch & {
    &.active,
    &:hover {
      color: var(--color-primary-800);
      border-color: var(--color-primary-800);


      &:after {
        @include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, center, center, no-repeat, 1.4rem, 1.4rem, colors.$color-primary-800);
      }

    }

    &:active {
      color: var(--color-primary-900);
      background-color: var(--color-neutral-white);
      border-color: var(--color-primary-900);

      &:after {
        @include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, center, center, no-repeat, 1.4rem, 1.4rem, colors.$color-primary-900);
      }
    }
  }


  .font-color-white & {
    @extend .action-secondary--inverted;
  }
}

.action-secondary--inverted {
  @extend .action-secondary;
  color: var(--color-neutral-white);

  border-color: var(--color-neutral-white);
  background: none;


  &:after {
    width: 2rem;
    height: 2rem;
    content: '';

    @include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, center, center, no-repeat, 1.4rem, 1.4rem, colors.$color-neutral-white);
  }


  &.disabled {
    color: var(--color-neutral-grey-300);

    &:after {
      @include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, center, center, no-repeat, 1.4rem, 1.4rem, colors.$color-neutral-grey-300);
    }
  }

  .not-touch & {
    &.active,
    &:hover {
      color: var(--color-primary-800);
      border-color: var(--color-primary-800);


      &:after {
        @include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, center, center, no-repeat, 1.4rem, 1.4rem, colors.$color-primary-800);
      }
    }

    &:active {
      color: var(--color-neutral-white);
      border-color: var(--color-neutral-white);
      background-color: transparent;

      &:after {
        @include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, center, center, no-repeat, 1.4rem, 1.4rem, colors.$color-neutral-white);
      }
    }
  }

}

.action-tertiary {
  color: var(--color-primary-900);
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 3.2rem */
  letter-spacing: 0.02rem;
  text-decoration: underline;
  text-underline-offset: 0.6rem;
  gap: 0.5rem;


  &:after {
    width: 3.2rem;
    height: 3.2rem;
    content: '';

    @include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, center, bottom, no-repeat, 1.84rem, 1.84rem, colors.$color-primary-900);
  }


  &.disabled {
    color: var(--color-neutral-grey-300);

    &:after {
      @include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, center, bottom, no-repeat, 1.84rem, 1.84rem, colors.$color-neutral-grey-300);
    }
  }

  &:focus {
    outline: 0.1rem solid var(--color-neutral-grey-300);
    outline-offset: 0.5rem;

  }

  .not-touch & {
    &.active,
    &:hover {
      color: var(--color-primary-800);


      &:after {
        @include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, center, bottom, no-repeat, 1.84rem, 1.84rem, colors.$color-primary-800);
      }
    }

    &:active {
      color: var(--color-primary-900);

      &:after {
        @include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, bottom, center, no-repeat, 1.84rem, 1.84rem, colors.$color-primary-900);
      }
    }
  }


}

.action-tertiary--inverted {
  @extend .action-tertiary;
  color: var(--color-neutral-white);


  &:after {


    @include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, center, bottom, no-repeat, 1.84rem, 1.84rem, colors.$color-neutral-white);
  }


  &.disabled {
    color: var(--color-neutral-grey-300);

    &:after {
      @include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, center, bottom, no-repeat, 1.84rem, 1.84rem, colors.$color-neutral-grey-300);
    }
  }

  .not-touch & {
    &.active,
    &:hover {
      color: var(--color-primary-700);

      &:after {
        @include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, center, bottom, no-repeat, 1.84rem, 1.84rem, colors.$color-primary-700);
      }
    }

    &:active {
      color: var(--color-neutral-white);

      &:after {
        @include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, bottom, center, no-repeat, 1.84rem, 1.84rem, colors.$color-neutral-white);
      }
    }
  }
}

.action-fab {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  @include background-svg.params(svg.$svg-arrow-right-diagonal, colors.$color-primary-900, center, center, no-repeat, 1.5rem, 1.5rem, colors.$color-neutral-white);
  text-indent: -9999rem;

  &:focus {
    outline: 0.1rem solid var(--color-neutral-grey-300);
    outline-offset: 0.5rem;
  }

  @include mq.mq($from: large) {
    width: 4.5rem;
    height: 4.5rem;
    @include background-svg.params(svg.$svg-arrow-right-diagonal, colors.$color-primary-900, center, center, no-repeat, 1.84rem, 1.84rem, colors.$color-neutral-white);

  }

  .not-touch & {
    &.active,
    &:hover {
      @include background-svg.params(svg.$svg-arrow-right-diagonal, colors.$color-primary-800, center, center, no-repeat, 1.5rem, 1.5rem, colors.$color-neutral-white);
      @include mq.mq($from: large) {
        @include background-svg.params(svg.$svg-arrow-right-diagonal, colors.$color-primary-800, center, center, no-repeat, 1.84rem, 1.84rem, colors.$color-neutral-white);
      }
    }

    &:active {
      @include background-svg.params(svg.$svg-arrow-right-diagonal, colors.$color-primary-900, center, center, no-repeat, 1.5rem, 1.5rem, colors.$color-neutral-white);
      @include mq.mq($from: large) {
        @include background-svg.params(svg.$svg-arrow-right-diagonal, colors.$color-primary-900, center, center, no-repeat, 1.84rem, 1.84rem, colors.$color-neutral-white);

      }
    }
  }

  .not-touch .c-three_columns-item:hover & {
    @include background-svg.params(svg.$svg-arrow-right-diagonal, colors.$color-primary-800, center, center, no-repeat, 1.5rem, 1.5rem, colors.$color-neutral-white);
    @include mq.mq($from: large) {
      @include background-svg.params(svg.$svg-arrow-right-diagonal, colors.$color-primary-800, center, center, no-repeat, 1.84rem, 1.84rem, colors.$color-neutral-white);
    }
  }


  &.disabled {
    @include background-svg.params(svg.$svg-arrow-right-diagonal, colors.$color-neutral-grey-300, center, center, no-repeat, 1.5rem, 1.5rem, colors.$color-neutral-white);
    border-color: var(--color-neutral-grey-200);
    @include mq.mq($from: large) {
      @include background-svg.params(svg.$svg-arrow-right-diagonal, colors.$color-neutral-grey-300, center, center, no-repeat, 1.84rem, 1.84rem, colors.$color-neutral-white);
    }
  }

  &--icon {
    background: var(--color-primary-900);

    .not-touch & { 
      &:hover {
        background: var(--color-primary-800);
      }
      &:active {
        background: var(--color-primary-900);
      }
    }
  }

}

.action-fab--inverted {
  @extend .action-fab;
  @include background-svg.params(svg.$svg-arrow-right-diagonal, colors.$color-neutral-white, center, center, no-repeat, 1.5rem, 1.5rem, colors.$color-primary-900);
  background-color: var(--color-neutral-white);
  @include mq.mq($from: large) {
    @include background-svg.params(svg.$svg-arrow-right-diagonal, colors.$color-neutral-white, center, center, no-repeat, 1.84rem, 1.84rem, colors.$color-primary-900);
  }

  .not-touch & {
    &.active,
    &:hover {
      background-color: var(--color-primary-700);
      @include background-svg.params(svg.$svg-arrow-right-diagonal, colors.$color-primary-700, center, center, no-repeat, 1.5rem, 1.5rem, colors.$color-primary-900);
      @include mq.mq($from: large) {
        @include background-svg.params(svg.$svg-arrow-right-diagonal, colors.$color-primary-700, center, center, no-repeat, 1.84rem, 1.84rem, colors.$color-primary-900);
      }
    }

    &:active {
      @include background-svg.params(svg.$svg-arrow-right-diagonal, colors.$color-neutral-white, center, center, no-repeat, 1.5rem, 1.5rem, colors.$color-primary-900);
      background-color: var(--color-neutral-white);
      @include mq.mq($from: large) {
        @include background-svg.params(svg.$svg-arrow-right-diagonal, colors.$color-neutral-white, center, center, no-repeat, 1.84rem, 1.84rem, colors.$color-primary-900);
      }
    }
  }
}

.action-contact {
  @extend .action-primary;
  display: inline-flex;
  align-items: center;
  padding: 0 0.5rem 0 1.5rem;
  min-height: 4rem;

  &:after {
    content: '';
    width: 3rem;
    height: 3rem;
    display: flex;
    border-radius: 50%;
    background-color: var(--color-neutral-white);
    margin-left: 1rem;
    @include background-svg.params(svg.$svg-contact-mail, colors.$color-neutral-white, center, center, no-repeat, 50%, 50%, colors.$color-primary-900);
  }

  .not-touch & {
    &.active,
    &:hover {
      color: var(--color-neutral-white);
    }
    &:active {
      background-color: var(--color-primary-900);
      color: var(--color-neutral-white);
      &:after {
        @include background-svg.params(svg.$svg-contact-mail, colors.$color-neutral-white, center, center, no-repeat, 50%, 50%, colors.$color-primary-900);
      }
    }
  }
}

.action-download {
  @extend .action;
  font-size: 2rem;
  @extend .font-weight-400;
  color: var(--color-primary-900);
  line-height: 160%;
  letter-spacing: 0.02rem;

  &:before {
    width: 3.2rem;
    height: 3.2rem;
    content: '';
    margin-right: 0.6rem;
    @include background-svg.params(svg.$svg-download, transparent, center, center, no-repeat, 100%, 100%, colors.$color-primary-900);
  }
}

.action-links {
  @extend .action;
  font-size: 2rem;
  @extend .font-weight-400;
  color: var(--color-primary-900);
  line-height: 160%;
  letter-spacing: 0.02rem;

  &:before {
    width: 3.2rem;
    height: 3.2rem;
    content: '';
    margin-right: 0.6rem;
    @include background-svg.params(svg.$svg-links, transparent, center, center, no-repeat, 100%, 100%, colors.$color-primary-900);
  }
}

.action-underline {
  text-decoration: underline;
  text-underline-offset: 0.6rem;
}

.action-footer {
  color: var(--color-neutral-white);

  .not-touch & {
    &:hover {
      color: var(--color-primary-700);
    }
  }
}
