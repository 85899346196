@use "../mixins/mq";
@use "../mixins/background-svg";
@use "../_config/colors";
@use "../_base/svg";

:root {

  //colors
  --color-black: #{colors.$color-black};
  --color-grey-border: #{colors.$color-grey-border};
  --color-grey-400: #{colors.$color-grey-400};
  --color-grey-300: #{colors.$color-grey-300};
  --color-grey-200: #{colors.$color-grey-200};
  --color-grey-100: #{colors.$color-grey-100};
  --color-white: #{colors.$color-white};
  --color-primary-1: #{colors.$color-primary-1};
  --color-primary-2: #{colors.$color-primary-2};
  --color-secondary-1: #{colors.$color-secondary-1};
  --color-secondary-2: #{colors.$color-secondary-2};

  --color-neutral-black-transparent: #{colors.$color-black-transparent};
  --color-primary-900-transparent: #{colors.$color-primary-1-transparent};

  // colors Collano
  --color-neutral-white: #{colors.$color-neutral-white};
  --color-neutral-grey-100: #{colors.$color-neutral-grey-100};
  --color-neutral-grey-200: #{colors.$color-neutral-grey-200};
  --color-neutral-grey-300: #{colors.$color-neutral-grey-300};
  --color-neutral-grey-400: #{colors.$color-neutral-grey-400};
  --color-neutral-grey-500: #{colors.$color-neutral-grey-500};
  --color-neutral-black: #{colors.$color-neutral-black};

  --color-primary-900: #{colors.$color-primary-900};
  --color-primary-800: #{colors.$color-primary-800};
  --color-primary-700: #{colors.$color-primary-700};
  --color-primary-600: #{colors.$color-primary-600};

  --color-secondary-900: #{colors.$color-secondary-900};

  --color-tertiary-900: #{colors.$color-tertiary-900};

  --color-error: #{colors.$color-error};
  --color-success: #{colors.$color-success};
  --color-warning: #{colors.$color-warning};
  --color-focus: #{colors.$color-focus};

  --color-gradient: #{colors.$color-gradient};


  --shadow-100: 0 1rem 1rem 0 rgba(0, 0, 0, 0.05);
  --shadow-200: 0 0 2rem 0 rgba(0, 0, 0, 0.15);;


  //body
  --body-font-family: "apercu", Helvetica, Arial, sans-serif;
  --body-color: var(--color-neutral-black);
  --body-bg-color: var(--color-neutral-white);
  --body-font-size: 1.8rem;
  --body-line-height: 1.6;
  --body-font-weight: 400;
  --body-letter-spacing: 0rem;
  --scroll-behavior: smooth;
  --hyphens-style: auto;

  //spacings

  --spacing-5: 0.5rem;
  --spacing-10: 1rem;
  --spacing-15: 1.5rem;
  --spacing-25: 2.5rem;
  --spacing-30: 3rem;
  --spacing-50: 5rem;
  --spacing-75: 7.5rem;
  --spacing-100: 10rem;

  // rounded corners
  --rounded-corners: 2rem;

  //layout
  --container-width: 140.8rem;
  --gutter: 5rem;
  --grid-gap: var(--gutter);
  --wrapper-gap: 1.6rem;


  --font-size-h1: 3.4rem;
  --font-size-h2: 3.0rem;
  --font-size-h3: 2.6rem;
  --font-size-h4: 2.4rem;
  --font-size-h5: 2.2rem;
  --font-size-h6: 2.0rem;

  --font-size-1000: 3.4rem;
  --font-size-900: 3.0rem;
  --font-size-800: 2.6rem;
  --font-size-700: 2.4rem;
  --font-size-600: 2.2rem;
  --font-size-500: 2.0rem;
  --font-size-400: 1.8rem;
  --font-size-350: 1.6rem;
  --font-size-300: 1.6rem;
  --font-size-200: 1.4rem;
  --font-size-100: 1.2rem;
  --font-size-50: 1.0rem;

  --font-weight-h1: 400;
  --font-weight-h2: 500;
  --font-weight-h3: 500;
  --font-weight-h4: 500;
  --font-weight-h5: 500;
  --font-weight-h6: 500;

  --font-weight-900: 900;
  --font-weight-700: 700;
  --font-weight-500: 500;
  --font-weight-400: 400;

  --line-height-h1: 1.2;
  --line-height-h2: 1.3;
  --line-height-h3: 1.3;
  --line-height-h4: 1.4;
  --line-height-h5: 1.4;
  --line-height-h6: 1.5;

  --letter-spacing-h1: normal;
  --letter-spacing-h2: normal;
  --letter-spacing-h3: normal;
  --letter-spacing-h4: normal;
  --letter-spacing-h5: normal;
  --letter-spacing-h6: normal;

  --spacing-h1: 7.5rem;
  --spacing-h2: 5rem;
  --spacing-h3: 5rem;
  --spacing-h4: 5rem;
  --spacing-h5: 4.8em;
  --spacing-h6: 5rem;


  --label-font-size: 1.7rem;
  --label-font-size-small: 1.7rem;

  --button-primary-min-height: 4.7rem;
  --button-primary-min-width: 0rem;
  --button-font-size: 1.8rem;

  --header-height: auto;
  --header-image-width: 27.5rem;
  --header-logo-spacing: 10.5rem;

  --hamburger-line-height: 2px;
  --hamburger-width: 4rem;
  --hamburger-spacing: 12px;

  --form-border: 1px solid #999;
  --form-border-radius: 4px;
  --formInput-height: 33px;

  --animation-duration: 0.25s;
  --animation-ease: cubic-bezier(.25, .46, .45, .75);

  --link-color: inherit;
  --link-color-hover: var(--color-primary-900);
  --link-text-decoration: underline;
  --link-text-decoration-hover: underline;

  --marker-color: var(--color-primary-900);

  // typography spacing
  --spacing-text: 1.2rem;
  --spacing-list: 3rem;
  --spacing-media: 2.5rem;

  --list-margin-top: 3rem;
  --list-item-padding-left: 1.4rem;
  --list-margin-left: 2rem;
  --list-item-margin-top: 1rem;


  //forms and fields

  --form-row-gap: 0 3.3rem;

  --label-font-size: 1.6rem;
  --label-line-height: 1.2;

  --field-margin-bottom: 5rem;
  --field-error-font-size: 1.3rem;
  --field-error-line-height: 1.5;
  --field-error-color: var(--color-error);
  --field-error-margin: .5rem 0 0;


  --input-font-size: 1.8rem;
  --input-font-weight: 500;
  --input-line-height: 1.5;
  --input-padding-inline: 1.2rem;
  --input-padding-block: 0;
  --input-border-color: var(--color-neutral-black, currentColor);
  --input-border-width: 0 0 .1rem 0;
  --input-outline-border-width: .1rem;
  --input-outline-padding-inline: 1.2rem;
  --input-outline-padding-block: 2rem;
  --input-border-radius: 0;
  --input-background-color: transparent;
  --input-transition: color var(--animation-duration) var(--animation-ease), border-color var(--animation-duration) var(--animation-ease);
  --input-select-padding-right: 2rem;
  --input-select-arrow-bg: var(--input-select-arrow-black);

  @include background-svg.to-custom-property(svg.$svg-chevron-down, colors.$color-black, --input-select-arrow-black);
  @include background-svg.to-custom-property(svg.$svg-chevron-down, colors.$color-error, --input-select-arrow-error);
  @include background-svg.to-custom-property(svg.$svg-chevron-down, colors.$color-white, --input-select-arrow-white);


  --check-size: 3.5rem;
  --check-color: currentColor;
  --check-border-width: .1rem;
  --check-border-color: currentColor;
  --check-transition: color var(--animation-duration) var(--animation-ease), border-color var(--animation-duration) var(--animation-ease);
  --label-option-padding-left: calc(var(--check-size) + 1rem);
  --label-option-font-size: 1.6rem;
  --check-bg-color-checked: currentColor;

  @include background-svg.to-custom-property(svg.$svg-check, colors.$color-white, --check-bg-image);
  @include background-svg.to-custom-property(svg.$svg-radio, colors.$color-white, --check-radio-bg-image);



  @include mq.mq($from: medium) {
    --gutter: 2.5rem;
    --wrapper-gap: 8rem;

  }
  @include mq.mq($from: large) {
    --gutter: 5rem;
    --container-width: 140.8rem;
    // rounded corners
    --rounded-corners: 4rem;

    --body-font-size: 2rem;

    --font-size-h1: 7.5rem;
    --font-size-h2: 5rem;
    --font-size-h3: 3.6rem;
    --font-size-h4: 2.8rem;
    --font-size-h5: 2.5rem;
    --font-size-h6: 2.2rem;

    --font-size-1000: 7.5rem;
    --font-size-900: 5.0rem;
    --font-size-800: 3.6rem;
    --font-size-700: 2.8rem;
    --font-size-600: 2.5rem;
    --font-size-500: 2.2rem;
    --font-size-400: 2.0rem;
    --font-size-350: 1.8rem;
    --font-size-300: 1.6rem;
    --font-size-200: 1.4rem;
    --font-size-100: 1.2rem;
    --font-size-50: 1.0rem;

    --font-weight-h1: 400;
    --font-weight-h2: 500;
    --font-weight-h3: 500;
    --font-weight-h4: 500;
    --font-weight-h5: 500;
    --font-weight-h6: 600;

    --line-height-h1: 1.2;
    --line-height-h2: 1.3;
    --line-height-h3: 1.3;
    --line-height-h4: 1.4;
    --line-height-h5: 1.4;
    --line-height-h6: 1.6;

    --letter-spacing-h1: 0;
    --letter-spacing-h2: 0;
    --letter-spacing-h3: 0.8;
    --letter-spacing-h4: 0.65;
    --letter-spacing-h5: 0.85;
    --letter-spacing-h6: 0;

    --spacing-h1: 14.7rem;
    --spacing-h2: 5rem;
    --spacing-h3: 5rem;
    --spacing-h4: 5rem;
    --spacing-h5: 5rem;
    --spacing-h6: 4.9rem;


    --list-item-padding-left: 1.8rem;

    --label-font-size: 2.1rem;

    --header-height: 21.5rem;

    --spacing-text: 1.5rem;
    --spacing-media: 3.3rem;

    --wrapper-gap: 16rem;

  }
}

$body-font-family: "apercu", Helvetica, Arial, sans-serif;

@media (prefers-reduced-motion: reduce) {
  :root {
    --animation-duration: 0;
    --animation-ease: none;
    --scroll-behavior: auto;
  }
}