@use "../mixins/mq";

.breadcrumb__wrapper {
  display: flex;
  visibility: visible;
}

.breadcrumb {
  display: flex;
  list-style: none;
  margin-top: 0;
  margin-left: 0;
  align-items: center;
  margin-bottom: 5rem;
  flex-wrap: wrap;
  @include mq.mq($from: large) {
    margin-bottom: 10rem;
  }
}

.breadcrumb__wrapper {
  position: relative;
  z-index: 2;
  max-width: 100vw;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 0;
  scroll-behavior: var(--scroll-behavior);
  -webkit-overflow-scrolling: touch;
}

.breadcrumb--child {
	position: fixed;
	left: -22px;
	top: 100%;
	display: none;
	//display: flex;
	flex-direction: column;
	width: auto;
	max-width: 100%;
	/*min-width: calc(100% + 25px);*/
	padding: 1rem 0;
	background-color: var(--color-neutral-grey-100);
	word-break: keep-all;
	white-space: nowrap;
}

.breadcrumb__item {
  //position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  height: 30px;
  overflow: visible;
  padding-left: 0;
  letter-spacing: 0.1rem;


  &:first-child {

  }

  &:not(:first-child) {
    margin-top: 0;
  }

  .icon-arrow-right-s {
    margin: 0 1rem;
    color: var(--color-neutral-grey-300);
    height: 1rem;
    width: 1rem;
  }

  &--home {

    width: 2rem;
    height: 2rem;

    .icon-home {
      --icon-width: 2rem;
      --icon-height: 2rem;
      margin-top: -0.2rem;
    }
  }
}

.breadcrumb__trigger {
  height: 30px;
  margin-left: var(--spacingSmall);
  color: var(--color-neutral-grey-100);

  .breadcrumb--light & {
    color: var(--color-neutral-white);
  }

  .icon {
    transform-origin: center center;
  }
}

.breadcrumb__item-title {
  display: inline-flex;
  align-items: center;
  height: 30px;
  color: var(--color-neutral-grey-300);
  text-decoration: none;

  font-size: var(--font-size-200);
  font-style: normal;
  font-weight: var(--font-weight-400);
  line-height: 150%;

  .breadcrumb--light & {
    color: var(--color-neutral-white);
  }
}

a.breadcrumb__item-title {
  .not-touch & {
    &:hover {
      text-decoration: underline;
    }
  }
}

.breadcrumb__item--home {
  margin-left: 0;
}

.breadcrumb__link--home {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--color-neutral-grey-300);
}

.breadcrumb__item--last {
  padding-right: var(--spacingRegular);
  color: var(--color-neutral-grey-300);
  cursor: default;
}

.breadcrumb__item--opened {
  .breadcrumb--child {
    display: flex;
  }

  .breadcrumb__trigger {
    .icon {
      transform: rotate(180deg);
    }
  }
}
