@use "../mixins/mq";

.component {

}


.c-edge + .c-edge,
.c-spaced + .c-edge {
	margin-top: 6rem;

	@include mq.mq($from: large) {
		margin-top: 12rem;
	}
}



.c-last {
	margin-bottom: 6rem;

	@include mq.mq($from: large) {
		margin-bottom: 12rem;
	}
	&.component--productInfos {
		margin-bottom: 0;
	}
}
