@use "../mixins/mq";
@use "../mixins/col";
@use "../_base/typography";
@use "../atoms/action";
@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/background-svg";

.footer {
  color: var(--color-neutral-white);
  background-color: var(--color-neutral-grey-500);
  .action-secondary {
    @extend .action-secondary--inverted;
  }
}

.footer__top {
  padding-top: 5rem;
  padding-bottom: 3rem;
  background-color: var(--color-neutral-grey-500);
  @include mq.mq($from: medium) {
    padding-right: 5rem;
    padding-left: 5rem;

  }

  @include mq.mq($from: large) {

    padding: 10rem 5rem 5rem 5rem;
  }
}

.footer__top-left {
  padding-bottom: 2.5rem;

  @include mq.mq($from: large) {
    padding-bottom: 0;
  }
}

.footer__top-right {
  display: flex;
  flex-wrap: wrap;

  @include mq.mq($from: large) {
    margin-top: 0;
    justify-content: flex-end;
  }
}

.footer__address {
  margin-bottom: 2.5rem;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 3rem */
  margin-top: 2.5rem;

  @include mq.mq($from: large) {
    font-size: 2.2rem;
    margin-top: 5rem;

  }
}

.footer__address,
.footer__contact {
  @include mq.mq($from: large) {
    //width: calc(100% * 3 / 6 - #{6rem});
    @include col.params(3, 6, 6rem);
  }
}

.footer__contact {
  @include mq.mq($from: large) {

  }

  &-phone {
    display: flex;
    text-decoration: none;
    font-size: 1.6rem;
    font-style: normal;
    @extend .font-weight-500;
    line-height: 150%;

    @include mq.mq($from: large) {
      font-size: 1.8rem;
    }

    &:before {
      content: '';
      --icon-height: 2.5rem;
      --icon-width: 2.5rem;
      width: var(--icon-width);
      height: var(--icon-height);
      margin-right: 1rem;
      @include background-svg.params(svg.$svg-contact-phone, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-white);
      background-size: contain;
    }

    .not-touch & {
      &:hover {
        color: var(--color-primary-700);

        &:before {
          @include background-svg.params(svg.$svg-contact-phone, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-primary-700);
          background-size: contain;
        }
      }
    }
  }
}

.footer__bottom {
  padding-top: 2rem;
  padding-bottom: 2rem;

  background-color: var(--color-neutral-grey-500);
  color: var(--color-neutral-white);
  font-size: 1.4rem;
  line-height: 150%;
  @extend .font-weight-500;

  @include mq.mq($from: medium) {
    padding-right: 5rem;
    padding-left: 5rem;

  }
  @include mq.mq($from: large) {

    padding: 2rem 5rem 5rem 5rem;
  }

  .row {
    @include mq.mq($from: large) {
      align-items: center;
      justify-content: space-between;
    }
  }
}

.footer__bottom-left {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0;

  @include mq.mq($from: large) {
    width: auto;
    flex-direction: row;
    margin-bottom: 0;

    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
}

.footer__copyright {
  font-size: 1.4rem;
  font-style: normal;
  @extend .font-weight-500;
  line-height: 150%;
  @include mq.mq($from: large) {
    order: 1;
    flex: none;
  }
}

.footer__linkboxes {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 2.5rem;
  @include mq.mq($from: large) {

    max-width: 35rem;
    margin-top: 0;
  }
}

.footer__linkbox {
  border-radius: 1.5rem;

  padding: 2.5rem;
  width: 100%;
  margin-bottom: 1.5rem;
  position: relative;
  text-decoration: none;
  margin-top: 0;
  @include mq.mq($from: large) {
    margin-bottom: 2.5rem;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
  &:before {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 1.5rem;
    opacity: 0.05;
    background: var(--color-neutral-white);
    display: block;
    position: absolute;
  }

  &:first-of-type {
    margin-top: 0;
  }

  &-icon {

    opacity: 0.3;
    --icon-width: 4rem;
    --icon-height: 4rem;

    @include mq.mq($from: large) {
      --icon-width: 6rem;
      --icon-height: 6rem;
    }

    .icon-contact-persons {
      --icon-width: 4rem;
      --icon-height: 4rem;

      @include mq.mq($from: large) {
        --icon-width: 6rem;
        --icon-height: 6rem;
      }
      &:after {
        @include background-svg.params(svg.$svg-contact-persons, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-white);
      }
    }

    .icon-contact-mail {
      --icon-width: 4rem;
      --icon-height: 4rem;

      @include mq.mq($from: large) {
        --icon-width: 6rem;
        --icon-height: 6rem;
      }
      &:after {
        @include background-svg.params(svg.$svg-contact-mail, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-white);

      }
    }
  }

  &-content {
    font-size: 2.2rem;
    font-style: normal;
    @extend .font-weight-500;
    line-height: 140%;
    color: var(--color-neutral-white);
    margin-top: 1rem;
    @include mq.mq($from: large) {
      font-size: 2.5rem;
    }
  }

  &-button {
    display: flex;
    margin-top: 0.9rem;
    justify-content: flex-end;
    @include mq.mq($from: large) {
      margin-top: 2rem;

    }
  }


  .not-touch & {
    &:hover {
      &:before {
        opacity: 0.1;
      }

      .footer__linkbox-icon {
        color: var(--color-primary-700);
        opacity: 1;

        .icon-contact-persons {
          &:after {
            @include background-svg.params(svg.$svg-contact-persons, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-primary-700);
          }
        }

        .icon-contact-mail {
          &:after {
            @include background-svg.params(svg.$svg-contact-mail, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-primary-700);
          }
        }
      }

      .footer__linkbox-content {
        color: var(--color-primary-700);
      }

      .footer__linkbox-button {

        button {
          @include background-svg.params(svg.$svg-arrow-right-diagonal, colors.$color-primary-800, center, center, no-repeat, 1.5rem, 1.5rem, colors.$color-neutral-white);
          @include mq.mq($from: large) {
            @include background-svg.params(svg.$svg-arrow-right-diagonal, colors.$color-primary-800, center, center, no-repeat, 1.84rem, 1.84rem, colors.$color-neutral-white);
          }
        }


      }
    }
  }
}