@use "../mixins/mq";
@use "../_base/typography";

.nav--main-nav {
  .nav__wrap--parent {
    justify-content: flex-start;

    @include mq.mq($from: large) {
      padding-left: var(--header-logo-spacing);
    }

    .nav__wrap--child {
      width: 100vw;
      left: 0;
      right: 0;
      position: fixed;
      transform: none;
      top: calc(var(--header-height) - 2.4rem);

      box-shadow: var(--shadow-100);


      padding: 2.4rem 0 5rem calc(var(--header-image-width) + var(--header-logo-spacing) + 2.5rem);
      display: none;
      left: 0;




      @include mq.mq($from: large) {
        padding: 2.4rem calc(100vw - calc(calc(var(--header-image-width) + var(--header-logo-spacing) + 2.5rem) + 104.5rem)) 5rem calc(var(--header-image-width) + var(--header-logo-spacing) + 2.5rem);

        column-gap: 5rem;
        flex-wrap: wrap;
        flex-direction: row;
      }

      &.nav__wrap--visible {
        max-width: 100%;
        @include mq.mq($from: large) {
          display: flex;

        }


      }

      &.nav__wrap--visible-overflow {

        @include mq.mq($until: large) {
          //display: none;
        }
      }

      .nav__item--child {
        display: flex;
        align-items: flex-end;
        background: none;
        padding-right: 0;
        margin-top: 0;


        @include mq.mq($from: large) {
          width: calc(calc(100% - 10rem) / 3);
          max-width: 31.5rem;
          border-bottom: 0.1rem solid var(--color-neutral-grey-200);
        }
      }

      .nav__link--child {
        font-size: 1.8rem;
        line-height: 150%;

        padding: 1.5rem 0;

        white-space: initial;

        @extend .font-weight-400;
        @include mq.mq($from: large) {
          width: 31.5rem;
        }

        &.is-active {
          color: var(--color-primary-900);
        }

        .not-touch & {
          &:hover {
            color: var(--color-primary-800);
          }
        }

      }
    }
  }

  .nav {
    &__item--parent {
      position: relative;
      //max-height: 7rem;
      width: 100%;

      //transition: max-height 0.5s var(--animation-ease);
      //overflow: hidden;

      border-bottom: 0.1rem solid var(--color-neutral-grey-400);
      margin-top: 0;

      @include mq.mq($from: large) {
        width: auto;

        margin-top: 0;
        margin-right: 5rem;
        //overflow: visible;
        border-bottom: 0;
      }

      &-opened {
        .nav__wrap--child {


          @include mq.mq($until: large) {
            display: block;
            position: relative;
            top: initial;
            left: initial;
            right: initial;
            box-shadow: none;
            padding: 0;
            border-bottom: 0;
            padding-bottom: 1.25rem;
          }


          .nav__item {
            margin: 0;

            .nav__link {
              border: none;
              padding: 1.25rem 0;
            }
          }
        }
      }

    }

    &__item--child {
      margin-bottom: 2rem;

      @include mq.mq($from: large) {
        margin-bottom: 0;
      }
    }

    &__link {
      @extend .font-weight-700;

      color: var(--color-neutral-white);

      @include mq.mq($from: large) {
        color: var(--color-neutral-black);
      }

      &--parent {
        display: flex;
        @extend .font-weight-500;
        min-height: 3.5rem;
        padding: 2rem 0;
        align-items: center;
        font-size: 2.2rem;


        line-height: 150%;

        @include mq.mq($from: large) {
          font-size: 2.5rem;
          height: 3.5rem;
          padding: 0;
          border-bottom: 0;
          line-height: 140%;

        }

        &.is-active {
          color: var(--color-primary-900);
        }

        .not-touch & {
          &:hover {
            color: var(--color-primary-800);
          }
        }
      }

      &--child {
        display: block;
      }
    }
  }
}
