@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/mq";
@use "../mixins/background-svg";

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.productFilter {

  .section__title {

    margin-bottom: 2.5rem;
    @include mq.mq($from: large) {
      margin-bottom: 5rem;
    }
  }

  .wrapper {
    position: relative;

    &--productFilter {
      padding-bottom: 5rem;

      @include mq.mq($from: large) {
        padding-bottom: 10rem;
      }
    }




    &--background {
      background: var(--color-neutral-grey-100);
    }
    &--first {

      padding-top: 5rem;
      padding-bottom: 2.5rem;

      @include mq.mq($from: large) {
        padding-top: 10rem;
        padding-bottom: 5rem;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background: var(--color-white);
        height: calc(5rem + 3.8rem);
        @include mq.mq($from: large) {
          height: calc(10rem + 4rem);
        }
      }
    }
  }


  &__list {

    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;
    padding: 0 3.4rem;
    @include mq.mq($from: medium) {
      gap: 2.5rem;
      padding: 0;
    }
    @include mq.mq($from: large) {
      gap: 5rem;
      padding: 0;
    }

    .product-card {
      width: 100%;
      box-sizing: border-box;
      @include mq.mq($from: medium) {
        width: calc(calc(100% - 2.5rem) / 2);
      }
      @include mq.mq($from: large) {
        width: calc(calc(100% - 15rem) / 4);
      }
    }
  }

  &__link {
    margin-top: 5rem;
  }

  &__loadmore {

    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
    @include mq.mq($from: large) {
      margin-top: 5rem;
    }

    .icon {
      display: none;
      animation: rotation 1s linear infinite;
    }

    &--active {
      .icon {
        display: block;

      }
    }
  }
}

.p-filter {
  display: flex;
  flex-direction: column;
  gap: 4.9rem;
  margin: 0 1.6rem;
  position: relative;
  @include mq.mq($from: large) {
    margin: 0;
    align-items: center;
  }

  &-categories {
    border-radius: 3rem;
    border: 0.1rem solid var(--color-neutral-grey-500);
    background: var(--color-neutral-grey-500);
    height: auto;
    padding: 1.3rem;


    @include mq.mq($from: large) {
      height: 8rem;
      border-radius: 10.3rem;
      padding: 1rem;
      max-width: 78rem;
    }

    ul {
      display: flex;
      margin: 0;
      flex-direction: column;
      @include mq.mq($from: large) {
        flex-direction: row;
        max-width: 78rem;
      }

      li {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
        @include mq.mq($from: large) {
          width: auto;

        }
      }
    }

    &__button {
      border-radius: 3rem;
      background: transparent;
      color: var(--color-white);
      font-size: 1.8rem;
      font-style: normal;
      font-weight: var(--font-weight-500);
      line-height: 150%;

      height: 5rem;
      width: 100%;

      text-align: left;
      padding-left: 2.5rem;
      @include mq.mq($from: large) {
        text-align: center;
        padding-left: 0;
        padding: 0 4rem;
        min-width: 25.3rem;
        height: 6rem;
        font-size: 2.2rem;
      }


      &--active {
        background: var(--color-white);
        color: var(--color-black);
      }
    }
  }

  &-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 5rem;
    margin: 0 1.6rem;
    @include mq.mq($from: large) {
      flex-direction: row;
      gap: 6.5rem;
      margin: 0;
      justify-content: center;
    }

    label, .p-filter-options__lightswitch-label {
      font-size: 1.4rem;
      font-style: normal;
      font-weight: var(--font-weight-400);
      line-height: 150%;
      display: flex;
      align-items: center;

      @include mq.mq($from: large) {
        align-content: flex-start;

      }
    }

    &__select {
      display: flex;
      flex-direction: column;

      width: 100%;
      @include mq.mq($from: large) {
        width: auto;
        align-items: center;

      }

      &-wrapper {
        position: relative;
        padding-bottom: 0.5rem;
        @include mq.mq($from: large) {
          padding-bottom: 0;
        }

        &:after {
          content: '';
          position: absolute;
          right: 0;
          bottom: 1.2rem;
          width: 1.5rem;
          height: 1.5rem;
          background: background-svg.svg-url(svg.$svg-arrow-down-s) no-repeat center;
          background-size: contain;
        }
      }

      label {
        margin-bottom: 0.5rem;
        width: 100%;
      }

      select {

        border-bottom: 0.1rem solid black;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        padding-right: 2rem;

        width: 100%;
        padding-bottom: 0.5rem;

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        font-weight: var(--font-weight-500);
        line-height: 150%;

        @include mq.mq($from: large) {
          width: auto;
          max-width: 22rem;
          padding-right: 2rem;
        }

      }
    }

    &__lightswitch {
      display: flex;

      width: 100%;
      @include mq.mq($from: large) {
        width: auto;
      }

      .p-filter-options__lightswitch-label {
        margin-left: 1rem;
      }

      &-switch {
        position: relative;
        display: inline-block;
        width: 5rem;
        height: 2.6rem;

        input {
          opacity: 0;
          width: 0;
          height: 0;

          &:checked + .p-filter-options__lightswitch-switch-slider {
            background-color: #1f62a3;
          }

          &:checked + .p-filter-options__lightswitch-switch-slider:before {
            -webkit-transform: translateX(2.2rem);
            -ms-transform: translateX(2.2rem);
            transform: translateX(2.2rem);
          }
        }

        &-slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: var(--color-neutral-grey-300);
          -webkit-transition: .4s;
          transition: .4s;
          border-radius: 2.6rem;

          &:before {
            position: absolute;
            content: "";
            height: 2.2rem;
            width: 2.2rem;
            left: 0.2rem;
            bottom: 0.2rem;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 50%;
          }
        }
      }
    }
  }

  &-reset {

    margin: 3rem 3.2rem 0 3.2rem;
    @include mq.mq($from: large) {
      margin: 0;
      margin-top: 5.5rem;
    }

    button {
      font-size: 1.4rem;
      font-style: normal;
      font-weight: var(--font-weight-400);
      line-height: 150%;
      text-decoration-line: underline;
      color: var(--color-neutral-grey-400)
    }
    &--mobile {
      display: block;
      @include mq.mq($from: 85em) {
        display: none;
      }
    }
    &--desktop {
      display: none;
      @include mq.mq($from: 85em) {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

}