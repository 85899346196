@use "../mixins/mq";
@use "../_base/svg";
@use "../_config/colors";
@use "../_base/typography";
@use "../mixins/background-svg";

.jobTeaser {
  background: var(--color-tertiary-900);
  &__list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 2.5rem 2.9rem;

    @include mq.mq($from: large) {
      padding-right: 3rem;
    }
  }

  section {
    padding: 5rem 0;
    @include mq.mq($from: large) {
      padding: 10rem 0;
    }
  }
  &__loader {
    width: 5rem;
    height: 5rem;
    border: 0.5rem solid var(--color-primary-900);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotationLoader 1s linear infinite;
  }
  &__lcon {
    display: flex;
    justify-content: center;
  }
  &__error {
    display: none;
    text-align: center;
  }
  &--loaded {
    .jobTeaser__lcon {
      display: none;
    }
  }
  &--error {
    .jobTeaser__lcon {
      display: none;
    }
    .jobTeaser__error {
      display: block;
    }
  }

}

.jobCard {
  border-radius: 3rem;
  border: 0.1rem solid var(--color-white);
  background: var(--color-white);


  padding: 2.9rem 2.3rem 2.5rem 2.5rem;
  box-shadow: 0 1rem 1rem 0 rgba(0, 0, 0, 0.05);
  width: 100%;
  @include mq.mq($from: large) {
    width: calc(calc(100% - 2.9rem) / 2);
    padding: 5rem;
  }
  &__title {



    color: var(--color-black);

    font-size: var(--font-size-h5);
    font-style: normal;
    font-weight: var(--font-weight-h5);
    line-height: var(--line-height-h5);
  }
  &__infos {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    padding: 0;
    margin: 2.5rem 0 0 0;

    li {

      font-size: 1.8rem;

      line-height: 150%;

      color: var(--color-black);


      font-style: normal;
      font-weight: var(--font-weight-500);

      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
      gap: 1.5rem;

      @include mq.mq($from: large) {
        font-size: var(--font-size-350);
      }

      &:after {
        width: 2rem;
        height: 2rem;
        content: '';
        display: block;
        background-size: contain;
      }
    }
    &-workload {

      &:after {

        @include background-svg.params(svg.$svg-workload, transparent, center, center, no-repeat, 2.6rem, 2.6rem, colors.$color-neutral-black);

      }
    }
    &-location {
      &:after {

        @include background-svg.params(svg.$svg-marker, transparent, center, center, no-repeat, 2.6rem, 2.6rem, colors.$color-neutral-black);
      }
    }
  }
  &__actions {
    padding: 0;
    margin: 2.5rem 0 0 0;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    @include mq.mq($from: large) {
      margin: 5rem 0 0 0;
    }
    @include mq.mq($from: 1525px) {
      flex-direction: row;


    }
    li {
      padding: 0;
      margin: 0;
      list-style: none;
    }
  }


}



@keyframes rotationLoader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}