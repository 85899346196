@use "sass:string";
//
//  Function to create an optimized svg url
//
@use "sass:math";

@function svg-url($svg){
  //
  //  Add missing namespace
  //
  @if not string.index($svg,xmlns) {
    $svg: str-replace($svg, '<svg','<svg xmlns="http://www.w3.org/2000/svg"');
  }
  //
  //  Chunk up string in order to avoid
  //  "stack level too deep" error
  //
  $encoded:'';
  $slice: 2000;
  $index: 0;
  $loops: math.ceil(math.div(string.length($svg), $slice));
  @for $i from 1 through $loops {
    $chunk: string.slice($svg, $index, $index + $slice - 1);
    //
    //   Encode
    //
    $chunk: str-replace($chunk,'"', '\'');
    $chunk: str-replace($chunk,'%', '%25');
    $chunk: str-replace($chunk,'&', '%26');
    $chunk: str-replace($chunk,'#', '%23');
    $chunk: str-replace($chunk,'{', '%7B');
    $chunk: str-replace($chunk,'}', '%7D');
    $chunk: str-replace($chunk,'<', '%3C');
    $chunk: str-replace($chunk,'>', '%3E');

    //
    //    The maybe list
    //
    //    Keep size and compile time down
    //    ... only add on documented fail
    //
    //  $chunk: str-replace($chunk, '|', '%7C');
    //  $chunk: str-replace($chunk, '[', '%5B');
    //  $chunk: str-replace($chunk, ']', '%5D');
    //  $chunk: str-replace($chunk, '^', '%5E');
    //  $chunk: str-replace($chunk, '`', '%60');
    //  $chunk: str-replace($chunk, ';', '%3B');
    //  $chunk: str-replace($chunk, '?', '%3F');
    //  $chunk: str-replace($chunk, ':', '%3A');
    //  $chunk: str-replace($chunk, '@', '%40');
    //  $chunk: str-replace($chunk, '=', '%3D');

    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }
  @return url("data:image/svg+xml,#{$encoded}");
}

//  Background svg mixin
@mixin params($svg, $color: transparent, $left: 0, $top: 0, $repeat: no-repeat, $width: 100%, $height: 100%, $fillColor: #000000){
  @if ($fillColor) {
    $svg: str-replace($svg, 'currentColor', $fillColor)
  }


  @if($svg) {
    background: $color svg-url($svg) $left $top $repeat;
    background-size: $width $height;
  } @else {
    background-color: $color;
  }

}

//  Helper function to replace characters in a string
@function str-replace($string, $search, $replace: '') {
  $index: string.index($string, $search);
  @return if($index,
      string.slice($string, 1, $index - 1) + $replace +
        str-replace(string.slice($string, $index +
        string.length($search)), $search, $replace),
      $string);
}

@mixin to-custom-property($svg, $fillColor: black, $custom-property: --svg-url){
  @if ($fillColor) {
    $svg: str-replace($svg, 'currentColor', $fillColor)
  }
  @if($svg) {
    #{$custom-property}:svg-url($svg);
  } 
}