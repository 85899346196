@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/mq";
@use "../mixins/background-svg";
@use "../_base/typography";

.downloadApi {

  &__headline {

    font-style: normal;
    font-size: 2.2rem;
    line-height: 150%;
    font-weight: var(--font-weight-500);
    padding-bottom: 0.5rem;
    border-bottom: 0.2rem solid var(--color-neutral-black);

  }

  &__accordion {
    padding-top: 1rem;
  }

  &__description {
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding-bottom: 5rem;
    @include mq.mq($from: large) {
      padding-bottom: 3rem;
    }

  }



  #almegodocs {
    .almego {
      padding: 0;
    }

    .almg-header {
      margin-bottom: 0.7rem;
      border-bottom: none;
      .almg-toolbar {

        gap: 5rem;
        padding: 0;

        .almg-select-container {
          margin: 0;
        }

        .almg-toolbar-slot {

          #almg-sds-search-input {
            &:focus-visible {
              outline: none;
            }
          }

          &#almg-region-selector {
            display: block;
            width: 100%;

            select {
              display: block;
              width: 100%;

              appearance: none;
              background-image: background-svg.svg-url(svg.$svg-arrow-down-s-black);
              background-repeat: no-repeat;
              background-position: right center;
              background-size: 1.5rem auto;

              &:focus-visible {
                outline: none;
              }
            }
          }

          &:last-of-type {

          }

          .almg-sds-search-btn {
            background: none;
            border: none;
            position: relative;

            &:after {
              @include background-svg.params(svg.$svg-search, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
              content: '';
              width: 2rem;
              height: 2rem;
              position: absolute;
              bottom: 1rem;
              right: 0;
            }

            .almg-sds-search-btn-icon {
              display: none;
            }
          }

          .almg-sds-search-input-icon {
            display: none;
          }

          .almg-sds-search {
            border-radius: 0;
            border: none;
            border-bottom: 0.1rem solid var(--color-neutral-black);


            @media screen and (max-width: 641px) {
              margin-bottom: 5rem;
            }

          }

          .almg-select {
            border-radius: 0;
            border: none;
            border-bottom: 0.1rem solid var(--color-neutral-black);
          }

        }
      }

    }

    .almg-product-list {
      border: none;
      .almg-no-results-found {
        font-size: 1.6rem;
        font-style: normal;
        font-weight: var(--font-weight-500);
        line-height: 150%;
        padding-top: 5rem;
        @include mq.mq($from: large) {
          font-size: 1.8rem;
        }
      }
      .almg-product-item {
        padding: 2rem 0;
        align-items: center;
        margin: 0;
        border-top: none;
        border-bottom: 0.1rem solid var(--color-neutral-grey-200);

        display: grid;
        grid-template-columns: calc(100% - 2.5rem) 2.5rem;

        @include mq.mq($from: large) {
          display: flex;
          padding: 2.5rem 0;
        }
        .almg-product-header {

          width: auto;
          flex: unset;
          padding: 0 0 0 5rem;
          position: relative;
          grid-row: 1;
          grid-column: 1/2;
          @include mq.mq($from: large) {
            width: calc(50% + 5rem);
            padding: 0 0 0 6rem;
          }

          &:after {
            content: '';
            display: block;
            --icon-width: 2.5rem;
            --icon-height: 2.5rem;
            width: 2.5rem;
            height: 2.5rem;
            @include background-svg.params(svg.$svg-document, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
            position: absolute;
            left: 0.8rem;
            top: 0.5rem;
            @include mq.mq($from: large) {
              --icon-width: 3rem;
              --icon-height: 3rem;
              width: 3rem;
              height: 3rem;
              left: 1.4rem;
              @include background-svg.params(svg.$svg-document, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
            }
          }

          .almg-product-name {
            font-size: 1.8rem;
            font-style: normal;
            font-weight: var(--font-weight-500);
            line-height: 150%;
            color: var(--color-neutral-grey-500);
            padding: 0;
            @include mq.mq($from: large) {

            }
          }

          .almg-product-number {


            font-size: var(--font-size-200);
            font-style: normal;
            font-weight: var(--font-weight-400);
            line-height: 150%;
            color: var(--color-neutral-grey-400);
            margin-top: 0.5rem;
            padding: 0;
          }


        }

        .almg-product-region {
          flex: unset;
          width: auto;


          grid-row: 2;
          grid-column: 1/2;
          padding: 0;
          margin-top: 2rem;
          @include mq.mq($from: large) {
            width: calc(50% - 3rem);
            margin-top: 0;

          }
          .almg-product-region-name {
            font-size: var(--font-size-200);
            font-style: normal;
            font-weight: var(--font-weight-500);
            line-height: 150%;
            color: var(--color-neutral-grey-500);
            padding-left: 5rem;
            @include mq.mq($from: large) {
              padding-left: 0;
            }
          }

          .almg-product-language-name {
            font-size: var(--font-size-200);
            font-style: normal;
            font-weight: var(--font-weight-400);
            line-height: 150%;
            color: var(--color-neutral-grey-400);
            padding: 0 0 0 5rem;
            margin-top: 0.5rem;

            @include mq.mq($from: large) {
              padding-left: 0;
            }
          }


        }

        .almg-product-sds {
          padding: 0;
          width: 2.5rem;
          grid-row: 1/3;
          grid-column: 2;
          display: flex;
          align-items: center;

          @include mq.mq($from: large) {
            width: 3rem;

          }
          .almg-product-sds-list {
            display: flex;
            flex-direction: column;
            gap: 3.5rem;
            @include mq.mq($from: large) {
              gap: 2rem;
            }

            .almg-product-sds-item {
              margin: 0;
              padding: 0;

              .almg-sds-download {
                margin: 0;

                .almg-sds-download-text {
                  display: none;
                }

                .almg-sds-download-icon {
                  &:before {
                    display: none;
                  }

                  &:after {
                    content: '';
                    display: block;
                    --icon-width: 2.5rem;
                    --icon-height: 2.5rem;
                    @include background-svg.params(svg.$svg-download, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-primary-900);
                    width: 2.5rem;
                    height: 2.5rem;
                    @include mq.mq($from: large) {
                      --icon-width: 3rem;
                      --icon-height: 3rem;
                      @include background-svg.params(svg.$svg-download, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-primary-900);
                      width: 3rem;
                      height: 3rem;
                    }
                  }

                }

                .almg-sds-arrowright-icon {
                  &:before {
                    display: none;
                  }

                  &:after {
                    content: '';
                    display: block;
                    --icon-width: 2.5rem;
                    --icon-height: 2.5rem;
                    @include background-svg.params(svg.$svg-globe, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-primary-900);

                    width: 2.5rem;
                    height: 2.5rem;

                    @include mq.mq($from: large) {
                      --icon-width: 3rem;
                      --icon-height: 3rem;
                      @include background-svg.params(svg.$svg-globe, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-primary-900);

                      width: 3rem;
                      height: 3rem;
                    }
                  }

                }

                &:hover {
                  .almg-sds-download-icon {


                    &:after {

                      --icon-width: 2.5rem;
                      --icon-height: 2.5rem;
                      @include background-svg.params(svg.$svg-download, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-primary-800);
                      width: 2.5rem;
                      height: 2.5rem;
                      @include mq.mq($from: large) {
                        --icon-width: 3rem;
                        --icon-height: 3rem;
                        @include background-svg.params(svg.$svg-download, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-primary-800);
                        width: 3rem;
                        height: 3rem;
                      }
                    }

                  }

                  .almg-sds-arrowright-icon {
                    &:after {
                      --icon-width: 2.5rem;
                      --icon-height: 2.5rem;
                      @include background-svg.params(svg.$svg-globe, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-primary-800);

                      width: 2.5rem;
                      height: 2.5rem;

                      @include mq.mq($from: large) {
                        --icon-width: 3rem;
                        --icon-height: 3rem;
                        @include background-svg.params(svg.$svg-globe, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-primary-800);

                        width: 3rem;
                        height: 3rem;
                      }
                    }

                  }
                }
              }
            }
          }
        }
      }
    }
  }
}