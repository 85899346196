@use "sass:color";
@use "../_config/colors";
@use "../_base/typography";
@use "../atoms/action";
@use "../mixins/mq";

@use "../_base/svg";
@use "../mixins/background-svg";


.cookie-banner {
  position: fixed;
  right: 0;
  bottom: 0;

  z-index: 100;


  padding: 2.5rem 1.6rem 2rem 1.6rem;
  width: 100%;
  background-color: var(--color-neutral-grey-100);
  color: var(--color-neutral-black);
  @include mq.mq($from: large) {
    padding: 3.7rem 0 3.6rem 0;
  }

  &__container {
    @include mq.mq($from: large) {
      display: flex;
      align-items: center;
    }
  }

  &__cta {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 5rem;
    margin-top: 2rem;

    @include mq.mq($from: large) {
      width: auto;
      flex-wrap: nowrap;
      flex-shrink: 0;

    }

  }

  &__settings {
    font-size: 1.8rem;
    font-style: normal;
    font-weight: var(--font-weight-400);
    line-height: 150%;
    text-decoration-line: underline;
    color: var(--color-primary-900);
  }

  &__text {
    @include mq.mq($from: large) {
      padding-right: 15%;
    }
  }

  &__title {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: var(--font-weight-500);
    line-height: 150%;
    margin-bottom: 0.5rem;

  }

  &__desc {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: var(--font-weight-400);
    line-height: 150%;


  }
}


.cookie-choices {
  margin-top: 2rem;
}

.cookie-choice {
  position: relative;


  &__checkbox {
    font-size: 1.7rem;
    position: absolute;
    left: 0;
    top: 0;
    width: calc(100% - 3rem);

    padding-top: 0.2rem;
    background-color: var(--color-neutral-white);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @include mq.mq($from: large) {
      padding-top: 0.3rem;
      font-size: 1.8rem;
    }

    .field__sub-label:before {
      transform: translateY(-0.5rem);

      @include mq.mq($from: large) {
        transform: translateY(-0.3rem);
      }
    }

    &:first-of-type {
      margin-top: 2rem;
    }

    .field__sub-label {
      font-weight: 700;
      padding-left: 4rem;
      z-index: 1;
      position: relative;
      cursor: pointer;

      &:before {
        border-color: var(--color-primary-900);
      }
    }

    input {
      opacity: 0;
      display: none;
      &:checked ~ .cookie-choice__checkbox-checkmark {
        border-color: var(--color-primary-900);
        background-color: var(--color-primary-900);
        &:after {
          display: block;
        }
      }
      &:disabled ~ .cookie-choice__checkbox-checkmark {
        border-color: var(--color-neutral-grey-300);
        background-color: var(--color-neutral-grey-300);
      }

    }

    &-checkmark {

      position: absolute;
      top: 0;
      left: 0;
      height: 3rem;
      width: 3rem;
      background-color: transparent;
      border: 0.2rem solid var(--color-primary-900);
      border-radius: 0;
      cursor: pointer;
      z-index: 0;

      &:after {
        position: absolute;
        display: none;
        content: '';
        left: -2px;
        top: -2px;
        width: 3rem;
        height: 3rem;
        --icon-width: 2.2rem;
        --icon-height: 2.2rem;
        @include background-svg.params(svg.$svg-check, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-white);
        background-size: 2.2rem 2.2rem;
      }

    }

    .not-touch & {
      &:hover {
        .cookie-choice__checkbox-checkmark {
          border-color: var(--color-primary-800);
        }
        input {
          &:checked ~ .cookie-choice__checkbox-checkmark {
            border-color: var(--color-primary-800);
            background-color: var(--color-primary-800);
          }
          &:disabled ~ .cookie-choice__checkbox-checkmark {
            border-color: var(--color-neutral-grey-300);
            background-color: var(--color-neutral-grey-300);
          }
        }
      }
    }
  }

  &__details {
    border-bottom: 1px solid var(--color-neutral-grey-100);

    p:last-child {
      margin-bottom: 2rem;
    }
  }

  &__icon {
    display: inline-flex;
    flex-shrink: 0;
    width: 4rem;
    height: 4rem;
    padding: 0.8rem;
    margin-right: 1.5rem;
    transform: translateY(-0.3rem);
    border-radius: 50%;
    background-color: var(--color-success);
    color: var(--color-neutral-white);
  }

  &__submit {
    margin-top: 2.4rem;

    @include mq.mq($from: large) {
      margin-top: 4rem;
    }
  }

  &__success {
    display: flex;
  }

  &__summary {
    list-style: none;
    height: 6.8rem;
    color: transparent;
    cursor: pointer;

    & + * {
      margin-top: 0;
    }

    &:before,
    &:after {
      position: absolute;
      right: 0;
      top: 3.2rem;
      display: block;
      content: '';
      width: 2.5rem;
      height: 0.3rem;
      background-color: var(--color-neutral-grey-100);
      border-radius: 3px;
    }

    &:after {
      transform: rotate(90deg);
      transition: transform var(--animation-duration) var(--animation-ease);

      .cookie-choice__details[open] > & {
        transform: rotate(0);
      }
    }

    &::marker,
    &::-webkit-details-marker {
      display: none;
    }
  }
}

