@use "../mixins/mq";

.lg-item {
  padding:  0 1.9rem 0 1.6rem;
  @include mq.mq($from: large) {
    padding: 0;
  }
}

.lg-next,
.lg-prev {
  --icon-width: 2rem;
  color: inherit;
  cursor: pointer;
  display: block;
  padding: .5rem 0;
  z-index: 1084;
  outline: none;
  border: none;
  position: absolute;
  top: calc(50% - calc(var(--icon-width) / 2));
  svg {
    max-width: 100%;
    max-height: 100%;
  }

}

.lg-next {
  right: 2.8rem;
  width: 2rem;
  height: 2rem;
  padding: 0;
  @include mq.mq($from: large) {
    right: 2.7rem;
    width: 3rem;
    height: 3rem;
  }

}

.lg-prev {
  left: 2.3rem;
  width: 2rem;
  height: 2rem;
  padding: 0;
  @include mq.mq($from: large) {
    left: 2.5rem;
    width: 3rem;
    height: 3rem;
  }
}

.lg-counter {
  font-size: 1.6rem;
  font-style: normal;
  font-weight: var(--font-weight-400);
  line-height: 150%;
  margin-top: 1.4rem;
  margin-left: 1.7rem;
  @include mq.mq($from: large) {
    margin-top: 2.5rem;
    margin-left: 2.5rem;
  }
}

.lg-sub-html {
  font-size: 1.8rem;
  font-style: normal;
  font-weight: var(--font-weight-400);
  line-height: 150%;
  padding-bottom: 2.5rem;
  text-align: center;
  @include mq.mq($from: large) {

  }
}

.lg-counter-current {
  font-weight: var(--font-weight-700);
}
.lg-counter-all {

}

.lg-outer .lg-img-wrap {
  width: auto;
  height: 100%;
  max-height: 69.84127vh !important;
  margin: auto 0;
}

//prevent drage/select issue, also prevent download*
.lg-image {
  pointer-events: none;
}

.lg-next.disabled,
.lg-prev.disabled {
  opacity: 0 !important;
  cursor: default;
}

.lg-next:hover:not(.disabled),
.lg-prev:hover:not(.disabled) {
  color: #fff;
}

.lg-single-item .lg-next,
.lg-single-item .lg-prev {
  display: none;
}



.lg-outer.lg-right-end .lg-object {
  animation: lg-right-end 0.3s;
  position: relative;
}

.lg-outer.lg-left-end .lg-object {
  animation: lg-left-end 0.3s;
  position: relative;
}

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.lg-media-overlap .lg-toolbar {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
}

.lg-toolbar .lg-icon {
  color: inherit;
  cursor: pointer;
  float: right;
  font-size: 2.4rem;
  height: 4.7rem;
  line-height: 2.7rem;
  padding: 1rem 0;
  text-align: center;
  width: 5rem;
  text-decoration: none !important;
  outline: medium none;
  will-change: color;
  transition: color 0.2s linear;
  background: none;
  border: none;
  box-shadow: none;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-size: 2rem;
}

.lg-toolbar .lg-icon.lg-icon-18 {
  font-size: 1.8rem;
}

.lg-toolbar .lg-icon:hover {
  color: #fff;
}

.lg-toolbar .lg-close {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='none'%3E%3Cg clip-path='url(%23clip0_3435_8362)'%3E%3Cpath d='M30 1.71427L28.2858 0L15 13.2857L1.71427 0L0 1.71427L13.2857 15L0 28.2858L1.71427 30L15 16.7143L28.2858 30L30 28.2858L16.7143 15L30 1.71427Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_3435_8362'%3E%3Crect width='30' height='30' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  background-size: auto 2rem;
  width: 2rem;
  height: 2rem;
  padding: 0;
  margin-top: 1.6rem;
  margin-right: 1.5rem;
  @include mq.mq($from: large) {
    background-size: auto 3rem;
    width: 3rem;
    height: 3rem;
    margin-top: 2.5rem;
    margin-right: 1.2rem;
  }
}

.lg-toolbar .lg-maximize {
  font-size: 2.2rem;
}

.lg-toolbar .lg-maximize:after {
  content: "+";
}

.lg-toolbar .lg-download {
  background-image: url("data:image/svg+xml,%3Csvg width='55' height='55' viewBox='0 0 55 55' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M42.0114 11.1418C40.5977 8.50352 38.5438 6.26055 36.0215 4.61484C33.1211 2.71992 29.7481 1.71875 26.2676 1.71875C17.618 1.71875 10.2875 7.88477 8.72349 16.2551C3.35669 17.2477 -0.566357 22.2535 0.0695804 27.9598C0.671143 33.3996 5.43208 37.4258 10.902 37.4258H19.9512C20.8407 37.4258 21.5625 36.7039 21.5625 35.8145C21.5625 34.925 20.8407 34.2031 19.9512 34.2031H10.8547C7.04341 34.2031 3.70903 31.4145 3.27505 27.6246C2.77661 23.3062 6.01216 19.5594 10.2231 19.3059C11.0051 19.2586 11.6454 18.6527 11.7313 17.875C12.5821 10.4973 18.8297 4.93711 26.2676 4.93711C31.961 4.93711 37.1774 8.28008 39.5622 13.4492C39.8415 14.0551 40.4559 14.4375 41.1219 14.3859C41.3711 14.3687 41.6161 14.3602 41.8567 14.3602C47.6317 14.3602 52.2723 19.323 51.7352 25.2055C51.2668 30.3488 46.7895 34.1988 41.629 34.1988H35.0504C34.161 34.1988 33.4391 34.9207 33.4391 35.8102C33.4391 36.6996 34.161 37.4215 35.0504 37.4215H41.5946C48.4868 37.4215 54.4122 32.218 54.9579 25.343C55.568 17.6687 49.5395 11.2191 42.0114 11.1332V11.1418Z M34.9419 42.566C34.6196 42.2437 34.2028 42.0848 33.7817 42.0848C33.3606 42.0848 32.9396 42.2437 32.6216 42.566L29.1454 46.0422V26.9641V25.3914C29.1454 24.4848 28.4106 23.75 27.504 23.75C26.5974 23.75 25.8626 24.4848 25.8626 25.3914V26.9641V46.0422L22.3864 42.566C21.7462 41.9258 20.7063 41.9258 20.0661 42.566C19.4259 43.2062 19.4259 44.2461 20.0661 44.8863L26.3481 51.1684C26.9884 51.8086 28.0282 51.8086 28.6685 51.1684L34.9505 44.8863C35.5907 44.2461 35.5907 43.2062 34.9505 42.566H34.9419Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat no-repeat;
  background-position: center center;
  background-size: 2rem;

}


.lg-error-msg {
  font-size: 1.4rem;
  color: inherit;
}


.lg-closing .lg-toolbar,
.lg-closing .lg-prev,
.lg-closing .lg-next, {
  opacity: 0;
  transition: transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-img-wrap,
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-video-cont {
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  will-change: transform, opacity;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-img-wrap,
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-video-cont {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.lg-icon:focus-visible {
  color: #fff;
  border-radius: .3rem;
  outline: .1rem dashed rgba(255, 255, 255, 0.6);
}

.lg-toolbar .lg-icon:focus-visible {
  border-radius: .8rem;
  outline-offset: -.5rem;
}

.lg-group:after {
  content: "";
  display: table;
  clear: both;
}

.lg-container {
  display: none;
  outline: none;
  color: #fff;
}

.lg-container.lg-show {
  display: block;
}

.lg-on {
  scroll-behavior: unset;
}

.lg-overlay-open {
  overflow: hidden;
}

.lg-toolbar,
.lg-prev,
.lg-next,
.lg-pager-outer,
{
  opacity: 0;
  will-change: transform, opacity;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-show-in .lg-toolbar,
.lg-show-in .lg-prev,
.lg-show-in .lg-next,
.lg-show-in .lg-pager-outer, {
  opacity: 1;
}

.lg-show-in .lg-hide-items .lg-prev {
  opacity: 0;
  transform: translate3d(-1rem, 0, 0);
}

.lg-show-in .lg-hide-items .lg-next {
  opacity: 0;
  transform: translate3d(1rem, 0, 0);
}

.lg-show-in .lg-hide-items .lg-toolbar {
  opacity: 0;
  transform: translate3d(0, -1rem, 0);
}



.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  text-align: left;
  opacity: 0.001;
  outline: none;
  will-change: auto;
  overflow: hidden;
  transition: opacity 0.15s ease 0s;
}

.lg-outer * {
  box-sizing: border-box;
}

.lg-outer.lg-zoom-from-image,
.lg-outer.lg-visible {
  opacity: 1;
}

.lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-prev-slide,
.lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-next-slide,
.lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-current {
  transition-duration: inherit !important;
  transition-timing-function: inherit !important;
}

.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide,
.lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide,
.lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
  transition-duration: 0s !important;
  opacity: 1;
}

.lg-outer.lg-grab img.lg-object {
  cursor: grab;
  user-select: none;
  -webkit-user-grab: none;
}

.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: grabbing;
}

.lg-outer .lg-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.lg-outer .lg-inner {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  transition: opacity 0s;
  white-space: nowrap;
}

.lg-outer .lg-item {
  display: none !important;
}

.lg-outer.lg-css3 .lg-prev-slide,
.lg-outer.lg-css3 .lg-current,
.lg-outer.lg-css3 .lg-next-slide {
  display: inline-block !important;
}

.lg-outer.lg-css .lg-current {
  display: inline-block !important;
}

.lg-outer .lg-item,
.lg-outer .lg-img-wrap {
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.lg-outer .lg-item:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.lg-outer .lg-img-wrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  white-space: nowrap;
  font-size: 0;
}

.lg-outer .lg-img-wrap:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.lg-outer .lg-item.lg-complete {
  background-image: none;
}

.lg-outer .lg-item.lg-current {
  z-index: 1060;
}

.lg-outer .lg-object {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: relative;
}

.lg-outer .lg-empty-html.lg-sub-html,
.lg-outer .lg-empty-html .lg-sub-html {
  display: none;
}

.lg-outer.lg-hide-download .lg-download {
  display: none;
}

.lg-outer .lg-first-slide .lg-dummy-img {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0!important;
}

.lg-outer.lg-components-open:not(.lg-zoomed) .lg-components {
  transform: translate3d(0, 0%, 0);
  opacity: 1;
}

.lg-outer.lg-components-open:not(.lg-zoomed) .lg-sub-html {
  opacity: 1;
  transition: opacity 0.2s ease-out 0.15s;
}

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: var(--color-neutral-black);
  opacity: 0;
  will-change: auto;
  transition: opacity 333ms ease-in 0s;
}

.lg-backdrop.in {
  opacity: 1;
}

.lg-css3.lg-no-trans .lg-prev-slide,
.lg-css3.lg-no-trans .lg-next-slide,
.lg-css3.lg-no-trans .lg-current {
  transition: none 0s ease 0s !important;
}

.lg-css3.lg-use-css3 .lg-item {
  backface-visibility: hidden;
}

.lg-css3.lg-fade .lg-item {
  opacity: 0;
}

.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}

.lg-css3.lg-fade .lg-item.lg-prev-slide,
.lg-css3.lg-fade .lg-item.lg-next-slide,
.lg-css3.lg-fade .lg-item.lg-current {
  transition: opacity 0.1s ease 0s;
}

.lg-css3.lg-use-css3 .lg-item.lg-start-progress {
  transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}

.lg-css3.lg-use-css3 .lg-item.lg-start-end-progress {
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  transform: translate3d(-100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  transform: translate3d(100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-container {
  display: none;
}

.lg-container.lg-show {
  display: block;
}

.lg-container.lg-dragging-vertical .lg-backdrop {
  transition-duration: 0s !important;
}

.lg-container.lg-dragging-vertical .lg-css3 .lg-item.lg-current {
  transition-duration: 0s !important;
  opacity: 1;
}

.lg-inline .lg-backdrop,
.lg-inline .lg-outer {
  position: absolute;
}

.lg-inline .lg-backdrop {
  z-index: 1;
}

.lg-inline .lg-outer {
  z-index: 2;
}

.lg-inline .lg-maximize:after {
  content: "\e909";
}

.lg-components {
  transform: translate3d(0, 100%, 0);
  will-change: transform;
  transition: transform 0.35s ease-out 0s;
  z-index: 1080;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;

  max-width: 68rem;
  margin: auto;
}

@keyframes lg-right-end {
  0% {
    left: 0;
  }

  50% {
    left: -3rem;
  }

  100% {
    left: 0;
  }
}

@keyframes lg-left-end {
  0% {
    left: 0;
  }

  50% {
    left: 3rem;
  }

  100% {
    left: 0;
  }
}



@include mq.mq($from: medium) {



  .lg-components {
    padding-top: 8rem;
  }

  .lg-sub-html {
    margin: 0;
  }
}

.lg-outer .lg-video-cont {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  max-width: 100%;
}

.lg-outer .lg-video-cont .lg-object {
  width: 100%;
  height: 100%;
  max-height: 69.84127vh !important;
}

.lg-outer .lg-has-iframe .lg-video-cont {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

.lg-outer .lg-video-object {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  z-index: 3;
}

.lg-outer .lg-video-poster {
  z-index: 1;
}

.lg-outer .lg-has-video .lg-video-object {
  opacity: 0;
  will-change: opacity;
  -webkit-transition: opacity 0.3s ease-in;
  -o-transition: opacity 0.3s ease-in;
  transition: opacity 0.3s ease-in;
}

.lg-outer .lg-has-video.lg-video-loaded .lg-video-poster,
.lg-outer .lg-has-video.lg-video-loaded .lg-video-play-button {
  opacity: 0 !important;
  width: 100%;
  height: auto;
}

.lg-outer .lg-has-video.lg-video-loaded .lg-video-object {
  opacity: 1;
}

@keyframes lg-play-stroke {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes lg-play-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.lg-video-play-button {
  width: 18%;
  max-width: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  cursor: pointer;
  transform: translate(-50%, -50%) scale(1);
  will-change: opacity, transform;
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0.17, 0.88, 0.32, 1.28), opacity 0.1s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0.17, 0.88, 0.32, 1.28), opacity 0.1s;
  -o-transition: -o-transform 0.25s cubic-bezier(0.17, 0.88, 0.32, 1.28), opacity 0.1s;
  transition: transform 0.25s cubic-bezier(0.17, 0.88, 0.32, 1.28), opacity 0.1s;
}

.lg-video-play-button:hover .lg-video-play-icon-bg,
.lg-video-play-button:hover .lg-video-play-icon {
  opacity: 1;
}

.lg-video-play-icon-bg {
  fill: none;
  stroke-width: 3%;
  stroke: #fcfcfc;
  opacity: 0.6;
  will-change: opacity;
  -webkit-transition: opacity 0.12s ease-in;
  -o-transition: opacity 0.12s ease-in;
  transition: opacity 0.12s ease-in;
}

.lg-video-play-icon-circle {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  fill: none;
  stroke-width: 3%;
  stroke: rgba(30, 30, 30, 0.9);
  stroke-opacity: 1;
  stroke-linecap: round;
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
}

.lg-video-play-icon {
  position: absolute;
  width: 25%;
  max-width: 120px;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  opacity: 0.6;
  will-change: opacity;
  -webkit-transition: opacity 0.12s ease-in;
  -o-transition: opacity 0.12s ease-in;
  transition: opacity 0.12s ease-in;
}

.lg-video-play-icon .lg-video-play-icon-inner {
  fill: #fcfcfc;
}

.lg-video-loading .lg-video-play-icon-circle {
  animation: lg-play-rotate 2s linear 0.25s infinite, lg-play-stroke 1.5s ease-in-out 0.25s infinite;
}

.lg-video-loaded .lg-video-play-button {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.7);
}


.lg-video-play-button {
  /*display: block;
  opacity: 1 !important;
  z-index: 1001;*/
  width: 9.8rem;
  height: 9.8rem;
  background: var(--video-loader) center center no-repeat;
  background-size: 100% 100%;
  animation: rotating 4s linear infinite;

  svg {
    display: none;
  }

}

@keyframes rotating {
  from {
    transform: translate(-50%, -50%) scale(0.7) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) scale(0.7) rotate(360deg);
  }
}
