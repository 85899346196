@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/mq";
@use "../mixins/background-svg";
.videoGallery {
  &__title {
    color: var(--color-neutral-black);
    font-size: var(--font-size-h2);
    font-style: normal;
    font-weight: var(--font-weight-400);
    line-height: var(--line-height-h2);
  }
  &__text {
    font-size: var(--font-size-600);
    font-style: normal;
    font-weight: var(--font-weight-400);
    line-height: 140%;
    margin-top: 1rem;
    @include mq.mq($from: large) {
      margin-top: 1.5rem;

    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5rem;
    gap: 1.5rem;
    @include mq.mq($from: large) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 5rem;
    }
  }
  &__item {

    overflow: hidden;

    cursor: pointer;
    position: relative;
    text-decoration: none;

    &--single-c {
      width: 100%;

      aspect-ratio: 0.87277354;
      @include mq.mq($from: large) {
        /*width: calc(calc(100% - 10rem) / 3);*/
        aspect-ratio: 0.872;
        grid-column: span 1;
      }
    }
    &--two-c {
      aspect-ratio: 1.84408602;
      width: 100%;
      @include mq.mq($from: large) {
        /*width: calc(calc(calc(100% - 10rem) / 3) * 2);*/
        aspect-ratio: 1.844;
        grid-column: span 2;
      }
    }
    &--image {


    }
    &--video {
      &:after {
        @include background-svg.params(svg.$svg-play-video, colors.$color-primary-900, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-white);

        content: '';
        position: absolute;
        border-radius: 50%;
        width: 4rem;
        height: 4rem;
        top: calc(50% - 2rem);
        left: calc(50% - 2rem);



        @include mq.mq($from: large) {
          width: 5rem;
          height: 5rem;
          top: calc(50% - 2.5rem);
          left: calc(50% - 2.5rem);
        }
      }
    }

    &--caption {
      padding-bottom: 6.2rem;
    }
    figure {
      position: relative;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        transition: transform var(--animation-duration) var(--animation-ease);
      }
      &:after {
        transition: opacity var(--animation-duration) var(--animation-ease);
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%);
        opacity: 1;

      }


    }
    &-caption {
      text-decoration: none;
    }

    &:hover {
      figure {
        img {
          transform: scale(1.05);

        }

        &:after {
          opacity: 0;
        }
      }
    }



  }
  &__loader {

    justify-content: center;
    margin-top: 5rem;
    display: none;
    svg {
      width: 5rem;
      height: 5rem;
      -webkit-animation: rotationLoader 2s infinite linear;

    }
  }

  &--loading {
    .videoGallery__loader {
      display: flex;
    }
  }
}

@-webkit-keyframes rotationLoader {
  from {-webkit-transform: rotate(0deg);}
  to   {-webkit-transform: rotate(359deg);}
}