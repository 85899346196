@use "../mixins/mq";
@use "vars";

html {
	font-size: 62.5%;
	text-size-adjust: none;
	font-variant: none;
	font-variant-ligatures: none;
	scroll-behavior: var(--scroll-behavior);
	scroll-padding-top: var(--header-height);
	background-color: var(--color-neutral-grey-500);
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;

	font-family: var(--body-font-family, vars.$body-font-family);

	font-size: var(--body-font-size);
	line-height: var(--body-line-height);
	color: var(--body-color);
	font-weight: var(--body-font-weight);
	letter-spacing: var(--body-letter-spacing);
	background-color: var(--body-bg-color);

	hyphens: var(--hyphens-style);
	word-break: break-word;

	[lang=de-CH] & {
		--hyphens-style: manual;
	}

	display: flex;
	min-height: 100vh;
	flex-direction: column;
	margin: 0;
}

main {
	flex: 1;
	max-width: 192rem;
	margin: 0 auto;
	width: 100%;
}
