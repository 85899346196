.alert {
    font-size: 1.3rem;
    padding: 1rem 1.5rem;
    border-radius: 0.1rem;
    margin-bottom: 1rem;
    color: var(--alert-color, #fff);
    background-color: var(--alert-bg-color, #ccc);
    
    &--error {
        --alert-bg-color: var(--color-error);
    }
    &--success {
        --alert-bg-color: var(--color-success);
    }
    &--warning {
        --alert-bg-color: var(--color-warning);
        --alert-color: var(--color-neutral-black);
    }
}