@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/mq";
@use "../mixins/background-svg";

.icon {
  position: relative;
  display: var(--icon-display, block);
  font-style: normal;
  color: var(--icon-color, currentColor);
  width: var(--icon-width, auto);
  height: var(--icon-height, auto);

  &:after {
    content: '';
    display: block;
    width: var(--icon-width, auto);
    height: var(--icon-height, auto);
  }
}

$icons: (
                ('arrow-right-diagonal', 32, 32),
                ('download', 32, 32),
                ('links', 32, 32),
                ('arrow-down-s', 32, 32),
                ('arrow-right-s', 32, 32),
                ('arrow-up-s', 32, 32),
                ('arrow-left-s', 32, 32),
                ('arrow-down-m', 32, 32),
                ('arrow-right-m', 32, 32),
                ('arrow-up-m', 32, 32),
                ('arrow-left-m', 32, 32),
                ('contact-mail', 32, 32),
                ('contact-email', 25, 25),
                ('contact-phone', 16, 25),
                ('contact-persons', 32, 32),
                ('placeholder', 32, 32),
                ('close', 32, 32),
                ('plus', 32, 32),
                ('minus', 32, 32),
                ('check', 32, 32),
                ('check-circled', 32, 32),
                ('menu', 32, 32),
                ('home', 32, 32),
                ('play', 32, 32),
                ('document', 32, 32),
                ('load', 32, 32),
                ('topic', 32, 32),
                ('hamburger', 32, 32),
                ('globe', 32, 32),
                ('search', 32, 32),
);


@each $name,
$widthSmall,
$heightSmall,
$widthMedium,
$heightMedium,
$widthLarge,
$heightLarge in $icons {
  .icon-#{$name} {
    --icon-width: #{calc($widthSmall / 10)}rem;
    --icon-height: #{calc($heightSmall / 10)}rem;

    @if $widthMedium and $widthMedium != 0 {
      @include mq.mq($from: medium) {
        --icon-width: #{calc($widthMedium / 10)}rem;
        --icon-height: #{calc($heightMedium  / 10)}rem;
      }
    }
    @if $widthLarge and $widthLarge !=  0 {
      @include mq.mq($from: large) {
        --icon-width: #{calc($widthLarge / 10)}rem;
        --icon-height: #{calc($heightLarge / 10)}rem;
      }
    }

  }
}

.icon-arrow-right-diagonal {
  &:after {
    @include background-svg.params(svg.$svg-arrow-right-diagonal, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}

.icon-download {
  &:after {
    @include background-svg.params(svg.$svg-download, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}

.icon-arrow-down-s {
  &:after {
    @include background-svg.params(svg.$svg-arrow-down-s, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}

.icon-arrow-right-s {
  &:after {
    @include background-svg.params(svg.$svg-arrow-right-s, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}

.icon-arrow-up-s {
  &:after {
    @include background-svg.params(svg.$svg-arrow-up-s, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}

.icon-arrow-left-s {
  &:after {
    @include background-svg.params(svg.$svg-arrow-left-s, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}

.icon-arrow-down-m {
  &:after {
    @include background-svg.params(svg.$svg-arrow-down-m, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}

.icon-arrow-right-m {
  &:after {
    @include background-svg.params(svg.$svg-arrow-right-m, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}

.icon-arrow-up-m {
  &:after {
    @include background-svg.params(svg.$svg-arrow-up-m, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}

.icon-arrow-left-m {
  &:after {
    @include background-svg.params(svg.$svg-arrow-left-m, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}

.icon-contact-mail {
  &:after {
    @include background-svg.params(svg.$svg-contact-mail, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}
.icon-contact-phone {
  &:after {
    @include background-svg.params(svg.$svg-contact-phone, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}
.icon-contact-persons {
  &:after {
    @include background-svg.params(svg.$svg-contact-persons, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}

.icon-placeholder {
  &:after {
    @include background-svg.params(svg.$svg-placeholder, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}
.icon-close {
  &:after {
    @include background-svg.params(svg.$svg-close, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}
.icon-plus {
  &:after {
    @include background-svg.params(svg.$svg-plus, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}
.icon-minus {
  &:after {
    @include background-svg.params(svg.$svg-minus, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}

.icon-check {
  &:after {
    @include background-svg.params(svg.$svg-check, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}
.icon-check-circled {
  &:after {
    @include background-svg.params(svg.$svg-check-circled, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}
.icon-menu {
  &:after {
    @include background-svg.params(svg.$svg-menu, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}
.icon-home {
  &:after {
    @include background-svg.params(svg.$svg-home, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}
.icon-play {
  &:after {
    @include background-svg.params(svg.$svg-play, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}
.icon-document {
  &:after {
    @include background-svg.params(svg.$svg-document, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}
.icon-load {
  &:after {
    @include background-svg.params(svg.$svg-load, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}
.icon-topic {
  &:after {
    @include background-svg.params(svg.$svg-topic, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}
.icon-hamburger {
  &:after {
    @include background-svg.params(svg.$svg-hamburger, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}
.icon-globe {
  &:after {
    @include background-svg.params(svg.$svg-globe, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}
.icon-search {
  &:after {
    @include background-svg.params(svg.$svg-search, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-black);
  }
}