@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/mq";
@use "../mixins/background-svg";

.hamburger {
  position: relative;
  display: block;
  width: var(--hamburger-width);
  height: var(--hamburger-width);
  padding: 0;
  overflow: hidden;
  color: currentColor;
  transform: translateZ(0);
}

.hamburger__text {
  display: none;
}

.hamburger__line {
  position: absolute;
  top: 50%;
  right: 0;

  display: block;
  height: var(--hamburger-line-height);
  width: calc(var(--hamburger-width) / 1);
  background-color: currentColor;
  border-radius: 2px;

  transition: transform .3s ease;
  transform-origin: 50% 50%;

  &:first-child,
  &:last-child {
    width: var(--hamburger-width);
  }

  &:first-child {
    transform: rotate(180deg) translateY(10px);
  }

  &:last-child {
    transform: translateY(10px);
  }
}

.hamburger__inner {
  position: relative;
  display: block;
  height: 16px;
  width: 100%;
}

.hamburger[aria-expanded="true"] {

  .hamburger__line {

    transform: translateX(150%);
    opacity: 0;

    &:last-child {
      transform: rotate(45deg);
      opacity: 1;
    }

    &:first-child {
      transform: rotate(-45deg);
      opacity: 1;
    }

  }

}

.hamburger__icon {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: var(--color-primary-900);
  display: flex;
  justify-content: center;
  align-items: center;


  &:after {
    content: '';
    --icon-width: 2.2rem;
    --icon-height: 1.22rem;
    @include background-svg.params(svg.$svg-hamburger, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-white);
    width: var(--icon-width);
    height: var(--icon-height);

  }


}


.header__nav--opened {
  .hamburger__icon {
    background-color: transparent;
    justify-content: flex-end;
    &:after {
      --icon-width: 2rem;
      --icon-height: 2rem;
      @include background-svg.params(svg.$svg-close, transparent, center, center, no-repeat, var(--icon-width), var(--icon-height), colors.$color-neutral-white);
      width: var(--icon-width);
      height: var(--icon-height);
    }
  }

}