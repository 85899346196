@use "../mixins/mq";

.imageText {

  &__overline {
    font-size: var(--font-size-300);
    font-style: normal;
    font-weight: var(--font-weight-500);
    line-height: 150%;
    margin-bottom: 2.5rem;
  }

  &__title {
    font-size: var(--font-size-h3);
    font-style: normal;
    font-weight: var(--font-weight-500);
    line-height: 130%;
    margin-bottom: 1rem;

  }

  &__text {
    font-size: var(--font-size-400);
    font-style: normal;
    font-weight: var(--font-weight-400);
    line-height: 150%;
    @include mq.mq($from: large) {
      line-height: 160%;
      letter-spacing: 0.02rem;
    }
  }

  &__image {
    aspect-ratio: 1.32945736;
    margin-bottom: 2.5rem;

    @include mq.mq($from: large) {
      margin-left: -11.2rem;
      aspect-ratio: 1.33333333;

      margin-right: calc(calc(calc(100% - calc(5.5 * var(--gutter))) / 6) + calc(var(--grid-gap) / 2));
      margin-bottom: 0;
    }

    .figure {
      height: 100%;

      picture {
        position: relative;
        display: block;
      }
    }

  }

  &__cta {
    margin-top: 2.5rem;

  }

  .container--imageText {
    @include mq.mq($from: large) {
      display: flex;
      align-items: center;
    }


    .imageText__imagecontainer {

      @include mq.mq($from: large) {
        width: 50%;
      }
    }

    .imageText__content {
      @include mq.mq($from: large) {
        width: 50%;
        padding-left: calc(var(--grid-gap) / 2);
      }
    }

    &-reverse {
      @include mq.mq($from: large) {
        flex-direction: row-reverse;
      }

      .imageText__image {
        @include mq.mq($from: large) {
          margin-left: calc(calc(calc(100% - calc(5.5 * var(--gutter))) / 6) + calc(var(--grid-gap) / 2));

          margin-right: -11.2rem;
        }
      }

      .imageText__text {
        @include mq.mq($from: large) {
          padding-left: 0;
          padding-right: calc(var(--grid-gap) / 2);
        }
      }

    }

  }
}