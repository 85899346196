@use "../mixins/mq";
@use "../_base/svg";
@use "../_config/colors";
@use "../_base/typography";
@use "../mixins/background-svg";

.product-info-card {
  color: var(--color-neutral-black);
  background: var(--color-neutral-white);
  position: relative;

  cursor: pointer;
  text-decoration: none;


  &__image {
    width: 100%;
    height: auto;
    aspect-ratio: 1.56001816;

    @include mq.mq($from: large) {
      /*height: 21.3rem;*/
      aspect-ratio: 1.47887323;
    }

    .figure-product-info-card {
      height: 100%;
      width: 100%;
    }

  }

  &__content {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    position: relative;
    --spacing-text: 0.5rem;
    @include mq.mq($from: large) {
      padding: 1.5rem 2rem 2rem 2rem;
    }
    &-title {
      font-size: var(--font-size-400);
      font-style: normal;
      font-weight: var(--font-weight-500);
      line-height: 150%;
      margin: 2rem 0 0.5rem 0;

      @include mq.mq($from: large) {
        letter-spacing: 0.02rem;
      }

    }
    &-description{
      font-size: var(--font-size-300);
      font-style: normal;
      font-weight: var(--font-weight-400);
      line-height: 150%;

    }
  }


}