@use "../mixins/mq";
@use "../_base/svg";
@use "../_config/colors";
@use "../_base/typography";
@use "../mixins/background-svg";

.product-card {
  color: var(--color-neutral-black);
  background: var(--color-neutral-white);
  position: relative;
  padding-bottom: 7rem;
  cursor: pointer;
  text-decoration: none;
  @include mq.mq($from: large) {
    padding-bottom: 8.5rem;
  }

  &__image {
    width: 100%;
    height: auto;
    aspect-ratio: 1.56;


    .figure-product-card {
      height: 100%;
      width: 100%;
    }

  }

  &__content {
    padding: 1.5rem 1.5rem 0 1.5rem;
    display: flex;
    flex-direction: column;
    position: relative;
    @include mq.mq($from: large) {
      padding: 1.5rem 2rem 0 2rem;
    }
  }

  &__subtitle {
    font-size: 1.2rem;
    font-style: normal;
    @extend .font-weight-400;
    line-height: 150%;

  }

  &__title {

    font-size: 1.8rem;
    font-style: normal;
    @extend .font-weight-500;
    line-height: 150%;
    margin: 0.5rem 0;

    @include mq.mq($from: large) {
      font-size: 2rem;
      line-height: 160%;
      letter-spacing: 0.02rem;
    }
  }

  &__teaser {
    font-size: 1.6rem;
    font-style: normal;
    @extend .font-weight-400;
    line-height: 150%;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__action {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;

    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 1.5rem;

    @include mq.mq($from: large) {

      padding: 2rem;
    }
  }

  .not-touch & {
    &:hover {
      .product-card__action {
        .action-fab {
          @include background-svg.params(svg.$svg-arrow-right-diagonal, colors.$color-primary-800, center, center, no-repeat, 1.5rem, 1.5rem, colors.$color-neutral-white);
          @include mq.mq($from: large) {
            @include background-svg.params(svg.$svg-arrow-right-diagonal, colors.$color-primary-800, center, center, no-repeat, 1.84rem, 1.84rem, colors.$color-neutral-white);
          }
        }
      }

    }

    &:active {
      .product-card__action {
        .action-fab {
          @include background-svg.params(svg.$svg-arrow-right-diagonal, colors.$color-primary-900, center, center, no-repeat, 1.5rem, 1.5rem, colors.$color-neutral-white);
          @include mq.mq($from: large) {
            @include background-svg.params(svg.$svg-arrow-right-diagonal, colors.$color-primary-900, center, center, no-repeat, 1.84rem, 1.84rem, colors.$color-neutral-white);

          }
        }
      }
    }
  }
}