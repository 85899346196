@use "../_base/svg";
@use "../_config/colors";

@use "../mixins/mq";
@use "../mixins/background-svg";

.lang-chooser {
  position: absolute;
  left: 1.6rem;
  top: 2.5rem;


  &:focus-visible {
    outline: none;
  }

  @include mq.mq($from: large) {

    display: block;
    position: relative;
    top: 0;
    left: 0;
    color: var(--color-neutral-white);
    text-transform: uppercase;
    cursor: pointer;

    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0.02rem;

    margin-top: 0.4rem;

    appearance: none;
    background: background-svg.svg-url(svg.$svg-arrow-down-s-white);
    background-size: 1rem;
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 1.5rem;

  }


  .not-touch & {
    &:hover {
      @include mq.mq($from: large) {
        color: var(--color-primary-700);
        background: background-svg.svg-url(svg.$svg-arrow-down-s-white-hover);
        background-size: 1rem;
        background-repeat: no-repeat;
        background-position: right center;
        padding-right: 1.5rem;
      }
    }
  }

  &--mobile {
    @include mq.mq($from: large) {
      display: none;
    }
  }

  &--desktop {
    display: none;
    @include mq.mq($from: large) {
      display: block;
    }

  }
}


.lang-chooser__list {
  gap: 2.5rem;


  .not-touch & {
    &:hover {

    }
  }

  @include mq.mq($from: large) {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.lang-chooser__item {
  position: relative;
  margin-top: 0;
  padding-left: 0;

  color: var(--color-neutral-grey-300);
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;


  .nav__link {
    color: var(--color-neutral-grey-300);
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;

    .not-touch & {
      &:hover {
        color: var(--color-primary-900);
      }
    }
  }

  &--is-active {
    color: var(--color-neutral-white);

  }

}
