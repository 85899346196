@use "../mixins/mq";

.facts {
  &__container {
    background-color: var(--color-primary-900);
    color: var(--color-white);
    border-radius: 2rem;
    padding-top: 2.5rem;
    padding-bottom: 5rem;
    overflow: hidden;
    @include mq.mq($from: medium) {
      padding-top: 3rem;
    }
    @include mq.mq($from: large) {
      border-radius: 4rem;
      padding-top: 7.5rem;
      padding-bottom: 10rem;
    }
  }

  &__title {
    font-size: var(--font-size-h3);
    font-style: normal;
    font-weight: var(--font-weight-400);
    line-height: 130%;

  }
  &__cta {
    margin-top: 2.5rem;

    &--desktoponly {
      @include mq.mq($until: large) {
        display: none;
      }
    }
    &--desktophidden {
      @include mq.mq($from: large) {
        display: none;
      }
    }
  }
  &__text {
    font-size: var(--font-size-h4);
    font-style: normal;
    font-weight: var(--font-weight-400);
    line-height: 140%;
    margin-top: 1rem;
    @include mq.mq($from: medium) {
      margin-top: 1.5rem;
    }
    @include mq.mq($from: large) {
      margin-top: 0;


      line-height: 150%;
    }
  }
  &__slides {
    position: relative;

    margin-top: 5rem;
    /*margin-bottom: 5rem;*/
    @include mq.mq($from: large) {
      margin-top: 7.5rem;
      /*margin-bottom: 10rem;*/
    }
  }
  &__backup {
    display: none;
  }
}
.factslide {

  opacity: 1;
  @include mq.mq($from: large) {
    display: flex;
  }
  &__content {
    hyphens: auto;
    z-index: 1;
    background-color: var(--color-white);
    padding: 3.4rem 2.5rem 4.4rem 2.5rem;
    @include mq.mq($from: medium) {
      padding: 2.5rem 3.4rem 2.5rem 3.4rem;
    }
    @include mq.mq($from: large) {
      padding: 5rem;
      width: 50%;
    }
  }
  &__title {
    color: var(--color-primary-900);
    font-size: 3.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    @include mq.mq($from: medium) {
      font-size: 3.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
    @include mq.mq($from: large) {
      font-size: 7.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }

  }
  &__subtitle {
    color: var(--color-primary-900);
    font-size: var(--font-size-700);
    font-style: normal;
    font-weight: var(--font-weight-400);
    line-height: 140%;
    margin-top: 1.5rem;
    @include mq.mq($from: medium) {

    }
    @include mq.mq($from: large) {
      line-height: 150%;
    }
  }
  &__text {
    color: var(--color-black);
    font-size: var(--font-size-400);
    font-style: normal;
    font-weight: var(--font-weight-400);
    line-height: 150%;
    margin-top: 1rem;
    @include mq.mq($from: medium) {

    }
    @include mq.mq($from: large) {
      line-height: 160%;
      letter-spacing: 0.02rem;
    }
  }

  &__pager {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--color-primary-900);
    color: var(--color-white);
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    font-size: 2.2rem;
    font-style: normal;
    font-weight: var(--font-weight-500);
    line-height: 140%;
    position: absolute;
    z-index: 2;
    top: -4rem;
    left: calc(50% - 4rem);

    @include mq.mq($from: large) {
      width: 16.5rem;
      height: 16.5rem;
      font-size: 3.6rem;
      line-height: 130%;

      top: calc(50% - 8.25rem);
      left: -8.25rem;
    }


  }
  &__image {
    aspect-ratio: 1;
    position: relative;
    z-index: 1;
    .figure-fact {
      height: 100%;
    }
    @include mq.mq($from: medium) {

    }
    @include mq.mq($from: large) {
      width: 50%;
    }
  }

  &--padding, &--first {
    .factslide__content {

      @include mq.mq($until: large) {
        padding-bottom: 6.3rem;
      }
      @include mq.mq($from: large) {

        padding-right: 9.7rem;
      }

    }
  }


}

