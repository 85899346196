@use "../mixins/mq";
@use "../_config/colors";

:root {
    --team-grid-col: 1;
    @include mq.mq($from: medium) {
        --team-grid-col: 2;
    }
    @include mq.mq($from: 1490px) {
        --team-grid-col: 3;
    }
}

.team-list {
    display: grid;
    grid-template-columns: repeat(var(--team-grid-col), 1fr);
    gap: 2.5rem;

    &--option {
        direction: rtl;
    }

    @include mq.mq($from: large) {
        gap: 5rem;
    }

}

.card {
    direction: ltr;

    &__image {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 2rem;
        padding-right: 2rem;
        background: linear-gradient(180deg, rgba(0,0,0,0) 50%, rgba(236,230,223,1) 50%, rgba(236,230,223,1) 100%);

        @include mq.mq($from: large) {
            padding-left: 3rem;
            padding-right: 3rem;
        }

        .card-linkedin {
            align-self: center;
            transform: translateY(42.5px);

            @include mq.mq($from: large) {
                transform: translateY(52.5px);
            }
        }
    }

    &__text {
        background: var(--color-tertiary-900, #ECE6DF);
        padding: 1.5rem 2rem 1.7rem;

        @include mq.mq($from: large) {
            padding: 1.5rem 3rem 3rem;
        }
    }

    &__location {
        letter-spacing: 0.02px!important;
        @include mq.mq($from: medium) {
            display: flex;
            min-height: 7rem;
            align-items: center;
        }
    }

    &__name {
        margin-top: 1.5rem;
        font-weight: 700;
        line-height: 1.5;

        @include mq.mq($from: large) {
            font-size: 2rem;
            line-height: 1.6;
            letter-spacing: 0.02rem;
        }
    }

    &__profession {
        margin-top: 0.5rem;
        letter-spacing: 0.41px;

        @include mq.mq($from: large) {
            margin-top: 0.3rem;
            letter-spacing: 0.71px;
        }
    }

    &__contacts {
        display: flex;
        margin-top: 1.5rem;
        font-size: 1.6rem;
        letter-spacing: 0.55px;
        line-height: 1.5;
        font-weight: 500;

        @include mq.mq($from: large) {
            font-size: 1.8rem;
            letter-spacing: 0.15px;
            margin-top: 1.3rem;
        }
    }

    &__phone {
        display: flex;
        padding-left: 0.4rem;

        .action {
            &:hover {
                color: var(--color-primary-800);
            }
            &:active {
                color: var(--color-primary-900);
            }
        }
    }
    &__email {
        display: flex;
        
        .action {
            &:hover {
                color: var(--color-primary-800);
            }
            &:active {
                color: var(--color-primary-900);
            }
        }
    }

    &__separator {
        width: 0.1px;
        height: 3.5rem;
        margin-right: 2rem;
        margin-left: 2rem;
        background-color: var(--color-neutral-grey-300);
    }

    &-linkedin {
        width: 4.5rem;
        height: 4.5rem;
        color: var(--color-white);
        .icon {
            width: 1.7rem;
            height: 1.7rem;
        }
    }

}