@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/mq";
@use "../mixins/background-svg";

$sizes: h1, h2, h3, h4, h5, h6;

@each $size in $sizes {
  #{$size},
  .#{$size} {
    --spacing-top: var(--spacing-#{$size});
    //--spacing-bottom: var(--spacing-#{$size});
    display: block;
    font-size: var(--font-size-#{$size});
    line-height: var(--line-height-#{$size});
    font-weight: var(--font-weight-#{$size});
    //font-weight: var(--heading-font-height);
    letter-spacing: var(--letter-spacing-#{$size});
    margin-top: var(--spacing-top);
    //margin-bottom: var(--spacing-bottom);

    .no-margin & {
      --spacing-top: 0;
      //--spacing-bottom: 0;
    }

    &:first-child {
      --spacing-top: 0;
    }

  }

  .font-size-#{$size} {
    font-size: var(--font-size-#{$size});
    line-height: var(--line-height-#{$size});
    letter-spacing: var(--letter-spacing-#{$size});
  }
}

strong,
b,
.font-weight-700 {
  font-weight: 700;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-400 {
  font-weight: 400;
}

.text-align-center {
  text-align: center;
}

.text-align-center {
  text-align: center;
}

.text-align-justify {
  text-align: justify;
}

.text-align-right {
  text-align: right;
}

.uppercase {
  text-transform: uppercase;
}

.font-color-white {
  color: var(--color-neutral-white);
}

blockquote {
  color: var(--color-primary-900);
  @extend .font-weight-400;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;


  &:before, &:after {
    display: none;
  }

  @include mq.mq($from: large) {


    font-size: 2.8rem;
    line-height: 150%;
  }
}

p {
  margin-top: var(--spacing-text);


  &:first-child {
    --spacing-text: 0;
  }
  .action {
    margin-top: 1rem;
  }

}
blockquote {
  &:first-child {
    margin-top: 0;
  }
}

.lead {
  color: var(--color-neutral-black);
  font-size: 2.2rem;
  font-style: normal;
  @extend .font-weight-400;
  line-height: 140%;
  margin-top: 1rem;
  letter-spacing: var(--letter-spacing-h5);
  @include mq.mq($from: large) {
    font-size: 2.5rem;
  }
}


::marker {
  color: var(--marker-color, currentColor);
  font-weight: 600;
  content: var(--marker-content, unsert);
}


ul, ol {
  margin-top: var(--spacing-list);
  margin-left: var(--list-margin-left);

  &:first-child {
    --spacing-list: 0;
  }

  ul, ol {
    --spacing-list: .2rem;
  }

}

.text, .teaserModuleM__title {
  a {
    .not-touch & {
      &:hover {
        // TODO inverted state but container not known at the moment
        color: var(--color-primary-800);
      }
    }

  }
  ul {

    margin: 0;


    li {
      list-style-type: none;
      background: background-svg.svg-url(svg.$svg-stroke) no-repeat left 1.3rem;
      margin-left: 0;
      padding-left: 2.8rem;
      &::marker {
        display: none;
      }
      @include mq.mq($from: large) {
        padding-left: 3.3rem;
        background: background-svg.svg-url(svg.$svg-stroke) no-repeat left 1.5rem;
      }
    }

    &.todo-list {
      li {
        list-style-type: none;
        background: background-svg.svg-url(svg.$svg-check-circled) no-repeat left 0.7rem;
        background-size: 1.8rem 1.8rem;
        margin-left: 0;
        padding-left: 2.8rem;
        &::marker {
          display: none;
        }

        input {
          display: none;
        }
        @include mq.mq($from: large) {
          padding-left: 3.3rem;
        }
      }

    }
  }

  ol {
    ::marker {
      font-size: 1.8rem;
      line-height: 150%;
      color: var(--color-neutral-black);
      font-style: normal;
      @extend .font-weight-500;
      letter-spacing: 0.02rem;
      @include mq.mq($from: large) {
        font-size: 2rem;
        line-height: 160%;
      }


    }
    &:not(.breadcrumb){
      list-style: none;
      margin-left: 0;
      li {
        position: relative;
        &::marker {
          display: none;
        }
        counter-increment: step-counter;
        padding-left: 2.8rem;
        @include mq.mq($from: large) {
          padding-left: 3.3rem;
        }


        &:before{
          position: absolute;
          left: 0;
          content: counter(step-counter) ".";

          font-size: var(--body-font-size);
          font-style: normal;
          font-weight: var(--font-weight-500);
          line-height: 150%;

          @include mq.mq($from: large) {
            font-size: 2rem;
            line-height: 160%;
            letter-spacing: 0.02rem;
          }

        }
      }

    }

  }

  ul, ol {


    li {
      color: var(--color-neutral-black);
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      @extend .font-weight-400;

      @include mq.mq($from: large) {
        font-size: 2rem;
        line-height: 160%;
        letter-spacing: 0.02rem;
      }
    }
  }
}


.unstyled-list {
  list-style: none;
  --list-item-padding-left: initial;
  --list-margin-left: initial;
}

.reset-list {
  list-style: none;
  --spacing-list: initial;
  --list-item-padding-left: initial;
  --list-margin-left: initial;
  --list-item-padding-left: initial;
  --list-item-margin-top: initial;
}


ol {
  //list-style-type: decimal-leading-zero;
  //--marker-content: counter(list-counter, decimal-leading-zero) ' ';
  //--marker-content: counter(list-counter) ' ';
  //counter-reset: list-counter;
  //list-style-position: inside;
}


li {
  padding-left: var(--list-item-padding-left);
  margin-top: var(--list-item-margin-top);
  //counter-increment: list-counter;
}

figcaption {
  color: var(--color-neutral-grey-400);
  font-size: 1.6rem;
  font-style: normal;
  @extend .font-weight-400;
  line-height: 150%;
  margin-top: 0.5rem;
  @include mq.mq($from: large) {
    margin-top: 1.5rem;
  }
}

.body-1-medium {
  font-size: 1.8rem;
  font-style: normal;
  @extend .font-weight-500;
  line-height: 150%;
  @include mq.mq($from: large) {
    font-size: 2rem;
    line-height: 160%;
    letter-spacing: 0.02rem;
  }
}
.subtitle-1-regular {
  font-size: 1.6rem;
  font-style: normal;
  @extend .font-weight-400;
  line-height: 150%;

}